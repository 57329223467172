import { surveyLocalization } from "survey-core";

export var serbianCyrillicStrings = {
  pagePrevText: "Назад",
  pageNextText: "Даље",
  completeText: "Заврши",
  previewText: "Прегледај",
  editText: "Измени",
  startSurveyText: "Започни",
  otherItemText: "Друго (упиши)",
  noneItemText: "Ништа",
  selectAllItemText: "Изабери све",
  progressText: "Страница {0} од {1}",
  indexText: "{0} {1}",
  panelDynamicProgressText: "Упис {0} од {1}",
  panelDynamicTabTextFormat: "Панел {panelIndex}",
  questionsProgressText: "Одговорено на {0}/{1} питања",
  emptySurvey: "Нема видљивих страница или питања у анкети.",
  completingSurvey: "Хвала на попуњавању анкете!",
  completingSurveyBefore: "Према нашим подацима, већ сте попунили ову анкету.",
  loadingSurvey: "Учитавам анкету...",
  placeholder: "Изабери...",
  ratingOptionsCaption: "Изаберите...",
  value: "вредност",
  requiredError: "Молимо одговорите на ово питање.",
  requiredErrorInPanel: "Молимо одговорите на бар једно питање.",
  requiredInAllRowsError: "Молимо одговорите на питања у свим редовима.",
  numericError: "Вредност би требало да буде нумеричка.",
  minError: "Вредност не би требало да буде мања од {0}",
  maxError: "Вредност не би требало да буде већа од {0}",
  textMinLength: "Молимо унесите бар {0} знак(ов)а.",
  textMaxLength: "Молимо унесите највише {0} знак(ов)а.",
  textMinMaxLength: "Молимо унесите најмање {0} и не више од {1} знак(ов)а.",
  minRowCountError: "Молимо попуните најмање {0} ред(ова).",
  minSelectError: "Молимо изаберите најмање {0} опција/е.",
  maxSelectError: "Молимо изаберите највише {0} опција/е.",
  numericMinMax: "'{0}' би требало да буде најмање {1} и највише {2}",
  numericMin: "'{0}' би требало да буде најмање {1}",
  numericMax: "'{0}' би требало да буде највише {1}",
  invalidEmail: "Молимо унесите исправну e-mail адресу.",
  invalidExpression: "Израз: {0} би требало да буде тачан.",
  urlRequestError: "Захтев је наишао на грешку '{0}'. {1}",
  urlGetChoicesError: "Захтев није пронашао податке, или је путања нетачна",
  exceedMaxSize: "Величина фајла не би требало да прелази {0}.",
  otherRequiredError: "Молимо унесите другу вредност.",
  uploadingFile:
    "Фајл се шаље. Молимо сачекајте неко време и покушајте поново.",
  loadingFile: "Учитавање...",
  chooseFile: "Изаберите фајлове...",
  noFileChosen: "Није изабран ниједан фајл",
  fileDragAreaPlaceholder:
    "Превуците и отпустите датотеку овде или кликните на дугме испод и одаберите датотеку за отпремање.",
  confirmDelete: "Да ли желите да избришете унос?",
  keyDuplicationError: "Ова вредност треба да буде јединствена.",
  addColumn: "Додај колону",
  addRow: "Додај ред",
  removeRow: "Уклони",
  emptyRowsText: "Нема редова.",
  addPanel: "Додај ново",
  removePanel: "Уклони",
  choices_Item: "ставка",
  matrix_column: "Колона",
  matrix_row: "Ред",
  multipletext_itemname: "текст",
  savingData: "У току је чување података на серверу...",
  savingDataError: "Дошло је до грешке и резултати нису сачувани.",
  savingDataSuccess: "Резултати су успешно сачувани!",
  saveAgainButton: "Покушајте поново",
  timerMin: "мин",
  timerSec: "сек",
  timerSpentAll: "Провели сте {0} на овој страници и {1} укупно.",
  timerSpentPage: "Провели сте {0} на овој страници.",
  timerSpentSurvey: "Провели сте {0} укупно.",
  timerLimitAll: "Провели сте {0} од {1} на овој страници и {2} од {3} укупно.",
  timerLimitPage: "Провели сте {0} од {1} на овој страници.",
  timerLimitSurvey: "Провели сте {0} од {1} укупно.",
  clearCaption: "Поништи",
  signaturePlaceHolder: "Потпиши овде",
  chooseFileCaption: "Изабери фајл",
  takePhotoCaption: "Фотографисање",
  cameraPlaceHolder:
    "Кликните на дугме испод да бисте снимили фотографију помоћу фотоапарата.",
  fileCameraDragAreaPlaceHolder:
    "Превуците и отпустите или изаберите датотеку за отпремање или снимање фотографије помоћу фотоапарата.",
  replaceFileCaption: "Замени датотеку",
  removeFileCaption: "Уклони овај фајл",
  booleanCheckedLabel: "Да",
  booleanUncheckedLabel: "Не",
  confirmRemoveFile: "Да ли сте сигурни да желите да уклоните овај фајл: {0}?",
  confirmRemoveAllFiles: "Да ли сте сигурни да желите да уклоните све фајлове?",
  questionTitlePatternText: "Наслов питања",
  modalCancelButtonText: "Откажи",
  modalApplyButtonText: "Примени",
  filterStringPlaceholder: "Откуцајте да бисте претражили...",
  emptyMessage: "Нема података за приказивање",
  noEntriesText:
    "Још увек нема ставки.\nКликните на дугме испод да бисте додали нову ставку.",
  noEntriesReadonlyText: "Нема ставки.",
  more: "Више",
  tagboxDoneButtonCaption: "У реду",
  selectToRankEmptyRankedAreaText: "Сви избори су рангирани",
  selectToRankEmptyUnrankedAreaText:
    "Превуците и отпустите избор овде да бисте их рангирали"
};

// Uncomment these two lines on creating a translation file. You should replace "en" and enStrings with your locale ("fr," "de," and so on) and your variable.
surveyLocalization.locales["cyr"] = serbianCyrillicStrings;
surveyLocalization.localeNames["cyr"] = "Српски";

// The following strings have been translated by a machine translation service
// Remove those strings that you have corrected manually
// indexText: "{0} of {1}" => "{0} {1}"
// panelDynamicTabTextFormat: "Panel {panelIndex}" => "Панел {panelIndex}"
// ratingOptionsCaption: "Select..." => "Изаберите..."
// fileDragAreaPlaceholder: "Drag and drop a file here or click the button below and choose a file to upload." => "Превуците и отпустите датотеку овде или кликните на дугме испод и одаберите датотеку за отпремање."
// signaturePlaceHolder: "Sign here" => "Потпишите овде"
// filterStringPlaceholder: "Type to search..." => "Откуцајте да бисте претражили..."
// emptyMessage: "No data to display" => "Nema podataka za prikazivanje"
// noEntriesText: "There are no entries yet.\nClick the button below to add a new entry." => "Још увек нема ставки.\nКликните на дугме испод да бисте додали нову ставку."
// noEntriesReadonlyText: "There are no entries." => "Нема ставки."
// more: "More" => "Више"
// tagboxDoneButtonCaption: "OK" => "У реду"
// selectToRankEmptyRankedAreaText: "All choices are ranked" => "Сви избори су рангирани"
// selectToRankEmptyUnrankedAreaText: "Drag and drop choices here to rank them" => "Превуците и отпустите избор овде да бисте их рангирали"
// takePhotoCaption: "Take Photo" => "Фотографисање"
// cameraPlaceHolder: "Click the button below to take a photo using the camera." => "Кликните на дугме испод да бисте снимили фотографију помоћу фотоапарата."
// fileCameraDragAreaPlaceHolder: "Drag and drop or select a file to upload or take a photo using the camera." => "Превуците и отпустите или изаберите датотеку за отпремање или снимање фотографије помоћу фотоапарата."
// replaceFileCaption: "Replace file" => "Замени датотеку"
