import "./graphs.css";

import { SVGProps } from "react";

import {
  LineChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip as ChartTooltip,
  XAxis,
  YAxis,
  Line,
  ReferenceDot,
  ReferenceLine,
} from "recharts";

import { useTheme } from "@mui/material";
import { Point } from "../../iat-types";
import { useTransliteration } from "../../providers/transliterate";
import PulseCircle from "../pulse-circle";

interface IATLineProps {
  data: Point[];
  marker?: Point;
  markerLabel?: string;
}

export const ResponseTimeByAgePlot = ({
  data,
  marker,
  markerLabel = "",
}: IATLineProps) => {
  const theme = useTheme();
  const tl = useTransliteration();

  const { primary, secondary } = theme.palette;

  if (!data || data.length === 0) return null;

  const tooltipValueFormatter = (value: any):[string,string] => {
    return ["s", (value / 1000).toFixed(2)];
  };

  const tooltipLabelFormatter = (label: number, props: any) => {
    return "" + label.toFixed(0) + "g";
  };

  return (
    <ResponsiveContainer width="100%" aspect={2 / 1}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          tick={theme.typography.caption as SVGProps<SVGTextElement>}
          tickFormatter={(val) => val.toFixed(0)}
          dataKey="x"
          padding={{ left: 20, right: 20 }}
          label={{
            dy: 6,
            position: "insideBottom",
            fill: theme.palette.text.secondary,
            value: tl("Узраст"),
            style: {
              ...theme.typography.caption,
              paddingTop: 8,
            } as SVGProps<SVGTextElement>,
          }}
        />
        <YAxis
          tick={theme.typography.caption as SVGProps<SVGTextElement>}
          tickFormatter={(val, idx) => (val / 1000).toFixed(2)}
          label={{
            position: "insideLeft",
            angle: -90,
            fill: theme.palette.text.secondary,
            value: tl("Просечно време (s)"),
            style: { ...theme.typography.caption, textAnchor: "middle" },
          }}
        />

        <ChartTooltip
          labelStyle={theme.typography.body1}
          contentStyle={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: 6,
          }}
          formatter={tooltipValueFormatter}
          separator=" "
          labelFormatter={tooltipLabelFormatter}
        />

        <Line
          isAnimationActive={false}
          //unit={units}
          type="monotoneX"
          dataKey="y"
          stroke={primary.main}
          strokeWidth={2}
          activeDot={{ r: 6 }}
          dot={false} //{{ fill: BLUE, r: 2 }}
        />
        {marker && (
          <>
            <ReferenceDot
              {...marker}
              radius={40}
              r={6}
              fill={secondary.main}
              shape={PulseCircle}
            />
            <ReferenceLine
              y={marker.y}
              stroke={secondary.main}
              strokeDasharray="3 3"
              label={{
                position: "insideLeft",
                angle: 0,
                fill: theme.palette.text.secondary,
                value: (marker.y/1000).toFixed(2) + " s",
                style: { ...theme.typography.caption, textAnchor: "start" },
              }}
            />
            <ReferenceLine
              x={marker.x}
              stroke={secondary.main}
              strokeDasharray="3 3"
              label={{
                position: "insideBottom",
                angle: 0,
                fill: theme.palette.text.secondary,
                value: marker.x,
                style: { ...theme.typography.caption, textAnchor: "middle" },
              }}
            />
          </>
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};
