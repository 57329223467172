
export const randomizeIndices = (m:number, n:number) => {
  
    const rnd = (x: number) => Math.floor(Math.random()*x);
  
    let arr: number[] = [];
    
    const res = Array<number>(n).fill(0);
  
    for(let i=0;i<n;i++){
      if (arr.length === 0) {
        arr = Array<number>(m).fill(0).map((_e,i,a)=>a[i]=i);
      }
      res[i] = arr.splice(rnd(arr.length),1)[0];
    }
    for(let i=m;i<n;i+=m){
      if (res[i-1] === res[i]){
        let t = res[i];
        res[i]=res[i+1];
        res[i+1] = t;
      }
    }
  
    return res;
  }