import { _log } from "../constants";

export async function copyDirectLink(id: string): Promise<string> {

    const s = window.location.origin + `/${id}`;
    try {
      await navigator.clipboard.writeText(s);
      _log(s)
      return s;
    } catch (_e) {
      console.error(_e);
      return "";
    }
}