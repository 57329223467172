import {IATConfig, IATLogEntry, Padezi} from '../iat-types';
import {StringWithId, educationLabels, financialLabels, citySizeLabels, genderMap, } from '../constants';
import {currUsedCountries, ageGroups, currConfigObject, currStats, currFilter, logEntry} from '../providers/app-state';
//import { countryNames } from './country-names';
import { useRecoilValue } from 'recoil';
import { participantAge } from './participant-age';
import { hasHashtags } from './hashtags';


const hashScoreTypes = ['self', 'low', 'mild', 'moderate', 'significant'] as const;
type hashScoreType = typeof hashScoreTypes[number];

export type HashScoreFlags = Partial<Record<hashScoreType, boolean>>;


// curve fit mock results when we don't have enough data
export const mockResponseTime = (age: number) =>  {
    
    const random = 300 + (Math.random()+0.6)*20;
    return age <25 ? 500-25*age/12 + random : 350+25*age/6 + random};

const deltaScore = (score: number, groupScore:number):number => {
    if (isNaN(groupScore)) return Math.abs(score);  // we don't have group score, assume it's 0
    return Math.abs(score - groupScore); 
}

const speedFactor = (art: number, groupArt:number):number => {
    return art/groupArt;
}


const severityText = (score:number):string => {
    const absscore = Math.abs(score);
    if (absscore > 1.5 ) return "велику, јаку";
    if (absscore > 1 ) return "умерену";
    if (absscore > 0.5 ) return "малу, благу";
    return "занемарљиву";
};

const speedText = (score:number):string => {
    const absscore = Math.abs(score);
    if (absscore > 1.5 ) return "значајно";
    if (absscore > 1 ) return "умерено";
    if (absscore > 0.5 ) return "мало";
    return "занемарљиво";
};

export const severityDeltaText = (score: number, groupScore:number):string => {

    const delta = deltaScore(score, groupScore);
    
    if (delta < 0.5 ) return "незнатно";
    if (delta < 1.0 ) return "мало";
    if (delta < 1.5 ) return "умерено";
    return "значајно";
}

export const speedDeltaText = (art: number, groupArt:number):string => {

    const delta = speedFactor(art, groupArt);
    
    if (delta > 1.3 ) return "значајно спорије";
    if (delta > 1.15 ) return "умерено спорије";
    if (delta > 1.1 ) return "мало спорије";
    if (delta > 1 ) return "незнатно спорије";

    if (delta < 0.7 ) return "значајно брже";
    if (delta < 0.85 ) return "умерено брже";
    if (delta < 0.9 ) return "мало брже";
    return "незнатно брже";
}

const idToText = (id: string|undefined, items: StringWithId[]):string => {
    
    if (id==="") return "";
    const item = items.find(i=>i.id === id);
    if (!item) return "";
    return item.extra || item.text;
}

const scoreToHashFlags = (cfg: IATConfig, log: IATLogEntry): HashScoreFlags | undefined => {
    if (!hasHashtags(cfg)) return undefined;
    const d = log.d_score;
    
    const res: HashScoreFlags = {self: d >= 0}

    if (res.self){
        if (d < .5)
            res.low = true;
        else if (d< -1)
            res.mild = true;
        else if (d< 1.5)
            res.moderate = true;
        else
            res.significant = true;
    }else{  // others
        if (d > -0.5)
            res.low = true;
        else if (d > -1.5)
            res.moderate = true;
        else
            res.significant = true;
    }
    return res;
}


export interface IReplacer{
    gender: string;
    from_country: string;
    ageRange: string;
    citySize: string;
    edu: string;
    finance: string;
    
    severity: string;
    speed: string;

    art: string;

    detailedReport?: boolean;
    hascomparedata: boolean;
    severityDelta: string;
    speedDelta: string;

    isHashTest: boolean;
    hashScoreFlags?: HashScoreFlags;

    signDifferent: boolean;

    catA: Padezi;
    catB: Padezi;
    cat1: Padezi;
    cat2: Padezi;
    }

export const useScoreReplacer = (/* log: IATLogEntry, filter: IFilterProps|undefined, group: IAveragesForFilterResponse */ ):IReplacer => {

const log = useRecoilValue(logEntry);
const countries = useRecoilValue(currUsedCountries);
const ageGroupValues = useRecoilValue(ageGroups);
const group = useRecoilValue(currStats);
const filter = useRecoilValue(currFilter);

const cfg = useRecoilValue(currConfigObject);
const categories = cfg.categories;

const ageRanges : StringWithId[] = ageGroupValues.map(el => ({id: el.id, text: el.title, extra: el.range}));

const age = participantAge(log);

const isHash = hasHashtags(cfg);

 return  {
    gender: idToText(filter.gender, genderMap), // мушког|женског|""
    from_country: idToText(filter.country, countries),
    ageRange: idToText(filter.ageGroupId, ageRanges),
    citySize: idToText(filter.citySize, citySizeLabels), // из великог града|из малог града|са села
    edu: idToText(filter.edu, educationLabels), // са незавршеном основном школом|са завршеном основном школом|са завршеном средњом школом|факултетски образованих|са завршеним мастером|са докторатом
    finance: idToText(filter.finance, financialLabels), // изузетно незадовољних|јако незадовољних|незадовољних|неодређено|задовољних|веома задовољних|изузетно задовољних
    severityDelta: severityDeltaText(log.d_score, group.averageDScore), // незнатно|мало|умерено|значајно
    speedDelta: speedDeltaText(log.art, isNaN(group.averageResponseTime) ? mockResponseTime(age) : group.averageResponseTime), // незнатно|мало|умерено|значајно
    //hascomparedata: !isNaN(group.averageDScore),
    hascomparedata: group.count >= 5,

    art: (log.art/1000).toFixed(2),
    speed: speedText(log.d_score),
    severity: severityText(log.d_score),

    isHashTest: isHash,
    hashScoreFlags: scoreToHashFlags(cfg, log),
    
    // Ako nije # test i ako je d_score manj od nule, zameni mesta kategorijama
    // Ovako pojednostavljujemo Mustache šablon za rezultate
    catA: !isHash && log.d_score < 0 ? categories.catB.label : categories.catA.label,
    catB: !isHash && log.d_score < 0 ? categories.catA.label : categories.catB.label,
    cat1: categories.cat1.label,
    cat2: categories.cat2.label,

    signDifferent: Math.sign(log.d_score) !== Math.sign(group.averageDScore)
  }
  
};


