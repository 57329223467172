
export type IGender = "male" | "female"; // | "other";

export type ICitySize = "s" | "m" | "l"; // ;

// const NUMBER_OF_BLOCKS = 7;

// type Tuple<
//   T,
//   N extends number,
//   R extends readonly T[] = [],
// > = R['length'] extends N ? R : Tuple<T, N, readonly [T, ...R]>;

// inspired by https://github.com/iatgen/iatgen

export const IATCategoryNames = ["catA", "catB", "cat1", "cat2"] as const;

export type IATCategories = typeof IATCategoryNames[number]; //"catA" | "catB" | "cat1" | "cat2";

export type Padezi = {
  n: string;  // nominativ    (ko, šta)
  g: string;  // genitiv      (od koga, od čega)
  d: string;  // dativ        (kome, čemu)
  a: string;  // akuzativ     (koga, šta)
  v: string;  // vokativ      (hej)
  i: string;  // instrumental (s kim, s čim)
  l: string;  // lokativ      (o kome, o čemu)
}

export type IATCategorySet = {[k in IATCategories]: IATCategory};

interface IChoice{
  value: number;
  label: string;
}

export interface SurveyQuestion{
  question: string;
  choices: IChoice[];
}

export interface IATConfig {
  hydrated?: boolean;       // set to true when urls are expanded
  id: string;               // must be folder-friendly, since it is used as folder name on server
  iatName: string;          // Friendly Test Name
  resultNote?: string;      // display this text as a note on results page
  description?: string;     // optional description
  intro?: string;           // intro tekst za # testove
  questionsPerBlock: [number, number, number, number, number, number, number ];  //number[];          // # of questions by the block. Default:  [20, 20, 40, 40, 20, 40, 40]
  // Categories
  categories : IATCategorySet;
  surveyDefinition?: string;
}


export interface IATCategory {
  label: Padezi;        // Category name
  plural?: boolean;
  datalabel: string;    // data label (usually 1 char) to put in the answers
  items: string[];      // can be words || (data) urls for images - we auto detect which they are
  gender?: boolean | IGender;
}


export interface IATState{
  phase: "intro" | "testing" | "results";
  currentRound: number;
  currentStimulus: number;
  responses: IATResponse[];
}

export interface IATQuestion{
  round: number;
  question: number;
  dataLabel: string;
  questionIndex: number;
  correct: "left" | "right";
  payload: string;
}

export interface IATResponse{
  round: number;
  question: number;
  dataLabel: string;
  questionIndex: number;
  isCorrect: boolean;
  responseTime: number; // ms 

}


export interface IAvailableConfig{
  id: string;
  count: number;
  name: string;
  description: string;
  active: number; // 1>active, 0>not active
  cfg: IATConfig;
}

export interface IATLogEntry{
  yob: number;      // year of birth for participant
  surveyMain?: string;
  date?: number;    // UNIX timestamp of date when test was taken (set by server)
  country?: string; // ISO 3166-1 alpha-2 code (or XX if unknown) (set by server)
  city?: string;    // City name (set by server)
  citySize: ICitySize; // city in which you grew up (small/medium/large)
  deviceType: number;  // was test done on mobile device (1) or desktop (2)?
  gender: IGender;        // gender (male/female/other)
  edu: number;        // education level
  finance: number;        // financial satisfaction (0-6)
  art: number;      // average response time (calculated over correct answers)
  d_score: number;        // calculated d-score
  longitude?: number; 
  latitude?: number;
  surveyRest?: any;
}

const Targets = ["country", "gender", "ageGroupId", "edu", "finance", "citySize", "surveyMain"] as const;

export type ITarget = typeof Targets[number];

export type IFilterProps = {
  [key in ITarget]?: string;
};

// export interface IFilterProps{
//   //age?: string;
//   country: string;
//   citySize: ICitySize | "";
//   gender: IGender | "";
//   edu: string;
//   finance: string;
//   ageGroupId: string;
// }

export interface IAgeGroup{
  id: string;
  min: number;
  max: number | null;
  range: string;
  title: string;
}

export interface ILocation{
  country: string;
  city?: string;
  longitude?: number; 
  latitude?: number;
  date: number;

}


export interface IAveragesForFilterResponse {
  count: number;
  averageDScore: number;
  averageResponseTime: number;
}

export interface Point {
  x: number | string;
  y: number;
}

export interface GraphProps {
  //data: IATLogEntry[];  // logs
  data: Point[];
  total?: number;
  marker?: IATLogEntry; // current value
}

