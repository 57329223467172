//import Button from "@mui/material/Button";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import {
  currConfigObject,
  currAnswers,
  logEntry,
  __isDebugMode,
  currFilter,
  currSurveyModel,
} from "../providers/app-state";
import { useTransliteration } from "../providers/transliterate";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import { _log } from "../constants";
import { IATLogEntry } from "../iat-types";
import {
  getLocationFromServer,
  writeIatAns,
  writeIatLog,
} from "../providers/iat-logs";
import { logToCsv } from "../providers/csv-convert";
import SurveyComponent from "../survey/survey-component";
import { SurveyFields } from "../survey/validate-response-fields";
import { LoadingButton } from "@mui/lab";
import CenteredSpinner from "../components/centered-spinner";

//interface IATUser extends Omit<IATLogEntry,'d'|'art'|'date'>{}

const UserData = ({
  onSubmit,
}: {
  onSubmit: (isSubmitted: boolean) => void;
}) => {
  const [log, setLog] = useRecoilState(logEntry);
  const cfg = useRecoilValue(currConfigObject);
  const isDebug = useRecoilValue(__isDebugMode);
  const answers = useRecoilValue(currAnswers);
  const setInitialFilter = useResetRecoilState(currFilter);

  const model = useRecoilValue(currSurveyModel);

  const [loading, setLoading] = useState(false);

  const tl = useTransliteration();

  const handleLogWrite = async (response: SurveyFields) => {
    setLoading(true);
    const location = await getLocationFromServer();
    const updatedLog: IATLogEntry = { ...log, ...location, ...response };
    setLog(updatedLog);

    if (!isDebug) {
      const res = await writeIatLog(cfg.id, updatedLog);
      if (!res.ok) {
        console.error(res);
      } else {
        _log(answers);
        const ans = await writeIatAns(cfg.id, answers);
        if (!ans.ok) {
          console.error(ans);
        }
      }
    } else {
      _log(logToCsv(updatedLog));
    }
    setLoading(false);
    // // ovo je sa zemljom, što za sada nećemo
    // setInitialFilter({...emptyFilter, country: updatedLog.country || ""})

    setInitialFilter();
    onSubmit(true);
  };
  //</Stack><Stack spacing={{ xs: 1, sm: 2 }} direction="row" flexWrap="wrap">

  return (
    <Stack sx={{maxWidth: "md", m: "auto", p: 2}} spacing={2}>
      {loading && <CenteredSpinner />}
      <SurveyComponent
        model={model}
        onComplete={(res) => handleLogWrite(res)}
      />


      {/* <LoadingButton
        loading={loading}
        variant="outlined"
        onClick={() => {
          onSubmit(false);
        }}
      >
        {tl(loading ? "Тренутак..." : "Откажи")}
      </LoadingButton> */}
    </Stack>
  );
};

export default UserData;
