import LoadingButton from '@mui/lab/LoadingButton';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import {validateConfig, ValidatorResult} from './verify-config/config-validator';

import JSZip from 'jszip';
import { IATCategoryNames, IATConfig } from '../iat-types';
import { isDataUrl, isUrl } from '../utils/is-url';
import { useTransliteration } from './transliterate';
import { getMimeTypeOfFile } from '../utils/bytes-mimetype';
import { BASIC_SURVEY_DEF, MAX_ZIP_SIZE } from '../constants';
import Tooltip from '@mui/material/Tooltip';
import { ChangeEvent, useState } from 'react';
import { fetchText } from './fetch';

const aspectRatio = async (src: string) => {
  let imgEl = new Image();

  let w=0,h=0;
  
  return new Promise<number>(resolve => {
    imgEl.onload = () => {
      w = imgEl.naturalWidth;
      h = imgEl.naturalHeight;
      resolve(w/h);
    };
    imgEl.src = src;
  });
}


function blobToDataURL(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = _e => resolve(reader.result as string);
      reader.onerror = _e => reject(reader.error);
      reader.onabort = _e => reject(new Error("Read aborted"));
      reader.readAsDataURL(blob);
    });
  }

const unpackFiles = async (zip: JSZip):Promise<ValidatorResult> => {
    const cfg = zip.file("config.json");
    if (!cfg) {
        return {
            valid: false,
            errors: [`'config.json' not found in zip file`],
            instance: null
        }
    };
    const content = await cfg.async("string");
    const validation = await validateConfig(content);
    if (validation.valid){
        const config = JSON.parse(content) as IATConfig;
        for await (let c of IATCategoryNames){
            const {items} = config.categories[c];
            for (let i=0;i<items.length;i++){
                const item = items[i];
                if (isUrl(item) && !isDataUrl(item)){
                    const f = zip.file(item) || zip.file("img/"+ item);
                    if (f===null) {
                        validation.valid = false;
                        if (!validation.errors) validation.errors = [];
                        validation.errors.push(`${item} not found in zip`);
                    }else{
                        const bytes = await f.async("uint8array");
                        const mimeType = getMimeTypeOfFile(bytes);
                        if (mimeType==="application/octet-stream"){
                            validation.valid = false;
                            if (!validation.errors) validation.errors = [];
                            validation.errors.push(`${item} is not a valid image file.`)
                        }else{
                            const blob = new Blob([bytes], {type: mimeType});
                            let dataUrl = await blobToDataURL(blob);
                            config.categories[c].items[i] = dataUrl;
                            const aspect = await aspectRatio(dataUrl);
                            if (aspect<0.9 || aspect > 1.1){
                              if (!validation.warnings) validation.warnings = [];
                              const e = `[WARN] ${item} is not square - image will be distorted or cropped!`;
                              validation.warnings.push(e)
                              console.warn(e)
                            }
                        }
                    };
                }
            }
        }
        const surveyFile = zip.file("survey.json");
        if (!surveyFile){
          if (!validation.warnings) validation.warnings = [];
          const e = `[WARN] survey.json not found, using default, basic survey`;
          validation.warnings.push(e);
          console.warn(e);
        }
        let surveyString = await surveyFile?.async("string") || await fetchText(BASIC_SURVEY_DEF);
        try {
          const _s = JSON.parse(surveyString);
        } catch (err) {
           if (!validation.errors) validation.errors = [];
           validation.errors.push(`Neispravna anketa u fajlu 'survey.json'`);
           validation.valid = false;
        }
        config.surveyDefinition = surveyString;
        //const survey = JSON.parse(surveyString);
        //config.surveyDefinition = transliterateJSON(survey);
        config.hydrated = true;
        validation.instance = validation.valid ? config : null;
    }
    return validation;

  };

  interface IUploadProps{
    //onLoad: (config:IATConfig)=>void;
    onLoad: (err:Partial<ValidatorResult>)=>void;
  }

  export const UploadConfig = (props:IUploadProps) => {
      
    const tl = useTransliteration();
    
    const [loading, setLoading] = useState(false);

    const {onLoad} = props;

      const fileUploaded = async (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const zip = new JSZip();
        if (!e.target.files) return;
        const f: File = e.target.files[0];
        if (f===undefined) return;  // User cancelled file selection
        if (f.size > MAX_ZIP_SIZE*1024*1024){
          onLoad({valid: false, instance: null, errors: [`Maksimalna dozvoljena veličina fajla je ${MAX_ZIP_SIZE}MB`]});
        }else{
          setLoading(true);
          const z = await zip.loadAsync(f);
          const res = await unpackFiles(z);
          setLoading(false);
          onLoad(res);
        }
        e.target.value = "";

      };

    return (
        <>
        <input
        accept="application/zip"
        hidden
        id="upload-button"
        type="file"
        onChange={fileUploaded}
      />
      <Tooltip arrow title={tl("Изаберите валидну ZIP архиву која садржи дефиницију теста који желите да додате")}>
      <label htmlFor="upload-button">
        <LoadingButton sx={{m:2}} variant="contained" size="small" component="span" loading = {loading} disabled={loading} startIcon={<AddCircleIcon/>}>
          {tl("Додај нови тест")}
        </LoadingButton>
      </label>
      </Tooltip>
      </>
    )
}