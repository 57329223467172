import {IATLogEntry, IATResponse} from '../iat-types';

export const logToCsv = (log: IATLogEntry, sep = "\t") => {
    const header = Object.keys(log).join(sep);
    const value = Object.values(log).join(sep);
    return header + "\n" + value;
}


export const responsesToCsv = (res: IATResponse[], sep = "\t", header=true) => {
    let s = header ? Object.keys(res[0]).join(sep) + "\n" : "";
    res.forEach( r =>{
        s+= Object.values(r).join(sep) + "\n";
    });
    return s;
}
