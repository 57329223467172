import "./graphs.css";

import { useEffect, useRef, useState } from "react";

import {
  ResponsiveContainer,
  Tooltip,
  Cell,
  Pie,
  Legend,
  PieChart,
  PieProps
} from "recharts";

import { Point } from "../../iat-types";
import { useTheme } from "@mui/material";
import { useTransliteration } from "../../providers/transliterate";

interface IATPieProps extends Partial<PieProps>{
  data: Point[];
}

export const IATPie = (props:IATPieProps) => {

  const tl=useTransliteration();
  const theme = useTheme();
  const { primary, secondary, success, warning, error } = theme.palette;

  const {data} = props;
  const PIE_COLORS = [
    primary.main,
    secondary.main,
    success.main,
    warning.main,
    error.main,
    primary.dark,
    secondary.dark,
    success.dark,
    warning.dark,
    error.dark,
  ];


  const [, setRedraw] = useState(0);
  
  const total = useRef(0);

  useEffect(() => {
    if (data.length > 0) {
      let sum = 0;
      data.forEach((x) => (sum += x.y));
      total.current = sum;
      setRedraw((old) => old + 1);
    }
  }, [data]);

  if (!data || data.length === 0) return null;

  const tooltipValueFormatter = (value: any, name: any) => {
    return [
      "" + value + ` (${((value * 100) / total.current).toFixed(0)}%)`,
      name
    ];
  };

  return (
    <ResponsiveContainer width="100%" aspect={2 / 1}>
      <PieChart>
        <Legend
          layout="horizontal"
          align="center"
          verticalAlign="bottom"
          style={theme.typography.body2}
          formatter={(value)=>tl(''+value)}
        />
        <Tooltip 
          formatter={tooltipValueFormatter} separator=": " 
          labelStyle={theme.typography.body2}
          //contentStyle={{backgroundColor: theme.palette.background.paper, borderRadius: 6}}
        />
        <Pie
          data={data}
          isAnimationActive={true}
          nameKey="x"
          dataKey="y"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill={primary.main}
          stroke={theme.palette.background.default}
          innerRadius="50%"
          minAngle={3}
          //startAngle={-180}
          //endAngle={180}
          //label={renderCustomizedLabel}
        >
          {data.map((_, idx) => (
            <Cell
              key={"cell-" + idx}
              fill={PIE_COLORS[idx % PIE_COLORS.length]}
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
