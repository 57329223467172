
import {ReferenceDotProps} from 'recharts';

interface PulseDotProps extends ReferenceDotProps{
    duration: number;
}
export default function PulseCircle({ duration = 1.8, fill = "grey", cx, cy, r, radius }:PulseDotProps) {


  return (
      <g fillRule="evenodd" strokeWidth="2" stroke={fill}>
        <circle cx={cx} cy={cy} r={r} fill={fill} />
        <circle cx={cx} cy={cy} r={r} fill="none" >
          <animate
            attributeName="r"
            begin="0s"
            dur={`${duration}s`}
            values={`1; ${radius}`}
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.165, 0.84, 0.44, 1"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-opacity"
            begin="0s"
            dur={`${duration}s`}
            values="1; 0"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.3, 0.61, 0.355, 1"
            repeatCount="indefinite"
          />
          {/* <animate
            attributeName="stroke-width"
            begin="0s"
            dur={`${duration}s`}
            values="2; 0"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.3, 0.61, 0.355, 1"
            repeatCount="indefinite"
          /> */}
        </circle>
        <circle cx={cx} cy={cy} r={r} fill="none">
          <animate
            attributeName="r"
            begin={`-${duration / 2}s`}
            dur={`${duration}s`}
            values={`1; ${radius}`}
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.165, 0.84, 0.44, 1"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-opacity"
            begin={`-${duration / 2}s`}
            dur={`${duration}s`}
            values="1; 0"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.3, 0.61, 0.355, 1"
            repeatCount="indefinite"
          />
          {/* <animate
            attributeName="stroke-width"
            begin={`-${duration / 2}s`}
            dur={`${duration}s`}
            values="2; 0"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.3, 0.61, 0.355, 1"
            repeatCount="indefinite"
          /> */}
        </circle>
      </g>
  );
}
