import {useState, MouseEvent} from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TranslitButton from '../layout/translit-button';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { availableConfigs, currConfigId, currConfigObject } from '../providers/app-state';
import { useTransliteration } from '../providers/transliterate';

const ITEM_HEIGHT = 48;

export default function TestSelectorMenu({title}:{title:string}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const configs = useRecoilValue(availableConfigs);
  const setConfig = useSetRecoilState(currConfigId);
  const configObject = useRecoilValue(currConfigObject);
  const tl = useTransliteration();
  
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeSelected = (id:string) => {
        setConfig(id);
        setAnchorEl(null);
  };

  return (
    <div>
      <TranslitButton
        tooltipText='Изабери тест'
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        //variant="outlined"
        size="small"
      >
        {title}
      </TranslitButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '30ch',
          },
        }}
      >
        {configs.map((item, index) => (
          <MenuItem key={item.id} selected={item.id === configObject.id} onClick={()=>changeSelected(item.id)}>
            {tl(item.name)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
