import "./graphs.css";

//import Masonry from "@mui/lab/Masonry";
import { useRecoilValue } from "recoil";
import { currConfigObject, surveyMainOptions } from "../../providers/app-state";
import { Chart } from "./chart-component";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Button from "@mui/material/Button/Button";
import MapIcon from "@mui/icons-material/Map";
import { useTransliteration } from "../../providers/transliterate";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";

export default function Graphs() {
  const { id: testId } = useRecoilValue(currConfigObject);
  const surveyMain = useRecoilValue(surveyMainOptions);
  
  const tl = useTransliteration();
  const navigate = useNavigate();

  return (
    <Grid
      container
      spacing={2}
      sx={{
        display: "flex",
        flexGrow: 1,
        alignContent: "stretch",
        justifyContent: "space-evenly",
        alignItems: "stretch",
        alignSelf: "stretch",
      }}
    >
      <Chart
        testId={testId}
        title="Државе"
        type="pie"
        kind="country"
        aggregate={3}
      >
        <Tooltip title={tl("Погледај резултате на мапи")}>
          <Button
            startIcon={<MapIcon />}
            //color="primary"
            onClick={() => {
              navigate(`/mapview/${testId}`);
            }}
          >
            {tl("Мапа")}
          </Button>
        </Tooltip>
      </Chart>
      <Chart testId={testId} title="Пол" type="pie" kind="gender" />
      <Chart
        testId={testId}
        title="Врста уређаја"
        subheader="Мобилни или десктоп"
        type="pie"
        kind="device"
      />
      <Chart
        testId={testId}
        title="Старосне групе"
        subheader="Број учесника по групама"
        type="pie"
        kind="agerange"
      />
      <Chart
        testId={testId}
        title="Време одзива по узрасту"
        subheader="Просечно време одзива (s)"
        type="line"
        kind="age"
      />
      <Chart
        testId={testId}
        title="Дистрибуција d-скора"
        subheader="На целом узорку"
        type="bar"
        kind="dscore"
      />
      {surveyMain && (
        <Chart
          testId={testId}
          title="Одговор на анкетно питање"
          subheader={tl(surveyMain.title)}
          type="pie"
          kind="survey"
          aggregate={4}
        />
      )}
    </Grid>

    // <Masonry columns={{ xs: 1, md: 2, lg: 3 }} spacing={2}>
    //     <Chart testId={testId} title="Учесници по земљи" type="pie" kind="country" aggregate={3}>
    //       <Tooltip title={tl("Погледај резултате на мапи")}>
    //         <Button
    //           startIcon={<MapIcon />}
    //           //color="primary"
    //           onClick={() => {
    //             navigate(`/mapview/${testId}`);
    //           }}
    //         >
    //           {tl("Мапа")}
    //         </Button>
    //       </Tooltip>
    //     </Chart>
    //     <Chart testId={testId} title="Учесници по полу" type="pie" kind="gender"/>
    //     <Chart testId={testId} title="Учесници по врсти уређаја" subheader="Мобилни или десктоп" type="pie" kind="device"/>
    //     <Chart testId={testId} title="Учесници по старосној групи" subheader="Број учесника по групама" type="pie" kind="agerange"/>
    //     <Chart testId={testId} title="Време одзива по узрасту" subheader="Просечно време одзива (s)" type="line" kind="age"/>
    //     <Chart testId={testId} title="Дистрибуција d-скора" subheader="На целом узорку" type="bar" kind="dscore"/>
    // </Masonry>
  );
}
