
import { ReactElement, Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import NoMatch from './error-page';
//import { ExtendedResultsPage } from './extended-results';
import Home from './home';
import ResultsPage from './results';
import SelectTest from './select-test';
import StartTest from './test';
//import UserData from './userdata';
import Login from '../providers/firebase';
import AdminPage from './admin-page-layout';
import { ADMIN_URL } from '../constants';
import CenteredSpinner from '../components/centered-spinner';
import TestKey from './test-key';
//import IATMap from '../components/maps/map';

interface IRoutes{
    path: string;
    element: ReactElement;
    title?: string;
}

const IATMap = lazy(()=>import("../components/maps/map"));

const appRoutesList: IRoutes[] = [
    {path: ADMIN_URL, element: <AdminPage/>},
    {path: '/login', element: <Login/>},
    {path: '/select', element: <SelectTest/>, title: "Select Test"},
    {path: '/test', element: <StartTest/>},
    {path: '/results', element: <ResultsPage/>},
    {path: '/mapview/:id', element: <IATMap/>},
    {path: '/testkey', element: <TestKey/>},
];


export const AppRoutes = () => (
    <Suspense fallback={<CenteredSpinner/>}>
    <Routes>
        <Route path="/" element={<Home />} >
            <Route path=":iat"  element={<Home />} />
        </Route>
        
        {appRoutesList.map( r => <Route key={r.path} path={r.path} element={r.element} /> )}
        <Route path="*" element={<NoMatch />} />
    </Routes>
    </Suspense>
    );
