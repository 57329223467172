import "./dscore-gauge.css";
import { useEffect } from "react";
import { useTheme } from "@mui/material";
import { useTransliteration } from "../../providers/transliterate";

export type MarkerProp = {
  score: number;
  color: string;
};

type GaugeProps = {
  catA: string;
  catB: string;
  dScore: number;
  marker?: number; //MarkerProp;
};

//const rad = (deg: number) => (deg * Math.PI) / 180;

const score2deg = (d: number) => -45 * d;

//const d2rad = (d:number) => rad(90+45*d);

export const IATGauge = ({ catA, catB, dScore, marker }: GaugeProps) => {
  const theme = useTheme();
  const tl=useTransliteration();
  
  useEffect(() => {
    const {style} = document.documentElement;
    const pointerAngle = score2deg(dScore);
    style.setProperty('--gauge-end-angle', `${pointerAngle}deg`)
    //const pointerTimer = setTimeout(()=>style.setProperty('--gauge-end-angle', `${pointerAngle}deg`),10);
    return ()=>{
      //clearTimeout(pointerTimer);
      style.setProperty('--gauge-end-angle', null);
    };
  }, [dScore]);

  useEffect(() => {
    const {style} = document.documentElement;
    const markerAngle = score2deg(marker || 0);
    style.setProperty('--marker-end-angle', `${markerAngle}deg`);
    //const markerTimer = setTimeout(()=>style.setProperty('--marker-end-angle', `${markerAngle}deg`),10);
    return ()=>{
      //clearTimeout(markerTimer);
      style.setProperty('--marker-end-angle', null);
    };
  }, [marker]);

  return (
      <svg
        id="Gauge"
        //xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 990 580"
        width="100%"
        height="100%"
      >
        <style>
          {
            ".st1{fill:gray}.st2{font-family:'Roboto'}.st4{font-size:42px}.st5{fill:none;stroke:gray;stroke-width:5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
          }
        </style>
        <linearGradient
          id="RAINBOW"
          gradientUnits="userSpaceOnUse"
          x1={28}
          y1={300}
          x2={962}
          y2={300}
        >
          <stop offset={0} style={{ stopColor: "#d20000" }} />
          <stop offset={0.15} style={{ stopColor: "#ffc000" }} />
          <stop offset={0.5} style={{ stopColor: "#00a400" }} />
          <stop offset={0.85} style={{ stopColor: "#ffc000" }} />
          <stop offset={1} style={{ stopColor: "#d20000" }} />
        </linearGradient>
        <path
          d="M54.1 546.9c-.7-10.5-1.1-21.2-1.1-31.9C53 270.9 250.9 73 495 73s442 197.9 442 442c0 11.1-.4 22-1.2 32.9"
          style={{
            fill: "none",
            stroke: "url(#RAINBOW)",
            strokeWidth: 50,
            strokeMiterlimit: 10
          }}
        />
        <g id="Pointer" className="gauge" fill="grey">
            <path d="M495,182l-33,333c0,18.2,14.8,33,33,33s33-14.8,33-33L495,182z" />
            <circle
              cx={495}
              cy={515}
              r={8}
              style={{ fill: theme.palette.background.paper }}
            />
        </g>
        {marker !== undefined && (
          <line
            key={"marker"}
            className="gauge-marker"
            opacity={0.5}
            stroke={theme.palette.primary.main}
            x1="495"
            y1="515"
            x2={495}
            y2={105}
            //transform={`rotate(${score2deg(marker)}, 495, 515)`}
          />
        )}
        <text
          id="VALUE"
          x={495}
          y={265}
          textAnchor="middle"
          style={theme.typography.h1}
          fill={theme.palette.text.primary}
        >
          {Math.abs(dScore).toFixed(2)}
        </text>

        <path
          id="SVGID_x5F_2_x5F_"
          style={{ fill: "none", stroke: "none" }}
          d="M495,35.5c64.7,0,127.5,12.7,186.7,37.7c57.1,24.2,108.4,58.7,152.4,102.7s78.6,95.3,102.7,152.4"
        />
        <text>
          <textPath
            href="#SVGID_x5F_2_x5F_"
            startOffset="50%"
            textAnchor="middle"
            baselineShift="20"
          >
            <tspan style={theme.typography.h2} fill={theme.palette.text.primary}>{tl(catB)}</tspan>
          </textPath>
        </text>
        <path
          id="SVGID_x5F_3_x5F_"
          style={{ fill: "none", stroke: "none" }}
          d="M53.2,328.3c24.2-57.1,58.7-108.4,102.7-152.4s95.3-78.6,152.4-102.7c59.1-25,121.9-37.7,186.7-37.7"
        />
        <text>
          <textPath
            href="#SVGID_x5F_3_x5F_"
            startOffset="50%"
            textAnchor="middle"
            baselineShift="20"
          >
            <tspan style={theme.typography.h2} fill={theme.palette.text.primary}>{tl(catA)}</tspan>
          </textPath>
        </text>
        <g style={theme.typography.h4} fill={theme.palette.text.primary} opacity={0.3}>
          <text transform="rotate(-90 336.727 190.595)">{"2"}</text>
          <text transform="rotate(-67.5 385.954 82.69)">{"1,5"}</text>
          <text transform="rotate(-45.001 454.204 -150.704)">{"1"}</text>
          <text transform="rotate(-22.5 679.222 -741.164)">{"0,5"}</text>
          <text transform="translate(482.667 166.132)">{"0"}</text>
          <text transform="rotate(22.5 -155.938 1604.998)">{"0,5"}</text>
          <text transform="rotate(45.001 53.135 1014.582)">{"1"}</text>
          <text transform="rotate(67.5 137.322 781.161)">{"1,5"}</text>
          <text transform="rotate(90 170.595 673.273)">{"2"}</text>
          <path
            className="st5"
            d="M59.5 515.5h35M92.5 348.8l32.3 13.4M186.7 207.4l24.8 24.8M327.9 112.8l13.4 32.4M494.5 79.5v35M661.2 112.5l-13.4 32.3M802.6 206.7l-24.8 24.8M897.2 347.9l-32.4 13.4M930.5 514.5h-35"
          />
        </g>
      </svg>
  );
};
