import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AssessmentIcon from "@mui/icons-material/Assessment";
import StorageIcon from "@mui/icons-material/Storage";
import BuildIcon from "@mui/icons-material/Build";

import AppTitle from "../layout/appbar";
import { useTransliteration } from "../providers/transliterate";

import DashboardPage from './admin-page-dashboard';
import AdminConfigs from './admin-page-manage-configs';
import BackupRestore from "./admin-page-backup-restore";
import { atom, useRecoilState } from "recoil";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const activeTabState = atom({ key: "ActiveTab", default: 0});


function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  if (value!==index) return null;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}


//const ToolsPage = () => <Box sx={{ bgcolor: "lightgray" }}>Tools</Box>;

interface TabDef{
    icon: React.ReactElement;
    label: string;
    page: React.ReactNode;
}

const tabPages: TabDef[] = [
  { icon: <AssessmentIcon />, label: "Резултати", page: <DashboardPage /> },
  { icon: <StorageIcon />, label: "Тестови", page: <AdminConfigs /> },
  { icon: <BuildIcon />, label: "Одржавање", page: <BackupRestore /> }
];

export default function AdminLayout() {

  const [activeTab, setActiveTab] = useRecoilState(activeTabState);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const tl = useTransliteration();

  return (
    <>
    <AppTitle title={"Администрација"}>
          <Tabs
            textColor="inherit"
            TabIndicatorProps={{style:{backgroundColor: "white", height: 4, marginBottom: 4}}}
            value={activeTab}
            onChange={handleChange}
            variant="fullWidth"
            aria-label="mui tabs"
          >
            {tabPages.map((p, i) => (
              <Tab
              key={p.label}
              icon={p.icon}
              iconPosition="start"
              label={tl(p.label)}
              {...a11yProps(i)}
              />
              ))}
          </Tabs>
        </AppTitle>
        {tabPages.map((p, i) => (
          <TabPanel key={'tab-panel-'+i} value={activeTab} index={i}>
            {p.page}
          </TabPanel>
        ))}
    </>
  );
}
