import './instructions.css';

import { fetchText } from "../providers/fetch";
import Mustache from "mustache";
import ReactMarkdown from "react-markdown";
import { useTransliteration } from '../providers/transliterate';
import { useEffect, useState } from 'react';
import CenteredSpinner from './centered-spinner';
import {useScoreReplacer} from '../utils/delta-score';

// type TMustache = {
//     //[k in IATCategories]: Padezi;
//     catA: Padezi;
//     catB: Padezi;
//     cat1: Padezi;
//     cat2: Padezi;
//     speed: string;
//     severity: string;
// }

// interface InstructionsProps{
//     mdText: string;
//     imageUrl?: string;
//     mustache?: TMustache; //Partial<keyof IATConfig>;
// }


// interface IScoreTextProps{
//     detailedReport?: boolean;
//     replacer: IReplacer
// }

export const ScoreText = ({detailedReport=false, templateUrl=""} ) => {
    
   //const [_, setRedraw] = useState(0);

    const replacer = useScoreReplacer();
    
        
    const [markdown, setMarkdown] = useState("");
    //const [text, setText] = useState("");
    
    const tl = useTransliteration();


    useEffect(()=>{
        fetchText(templateUrl).then( (md)=>{
            setMarkdown(md);
        } );

    }, [markdown, templateUrl]);

    if (markdown==="") return <CenteredSpinner/>;

    const text = Mustache.render(markdown, {...replacer, detailedReport});

    return  (
            <ReactMarkdown 
                className="printableContainer"
                children={tl(text)}
                components={{
                   // strong: ({node, ...props}) => <b style={{color: primary }} {...props} />,
                    table: ({node, ...props}) => <table width="100%" {...props} />
                    }}
            />
        );

  };


