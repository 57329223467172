import { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/system/Box';
import { useRecoilValue } from 'recoil';
import { currConfigObject, currStats, logEntry } from '../providers/app-state';
import { useTransliteration } from '../providers/transliterate';
import { useScoreReplacer } from '../utils/delta-score';
import {FilterFormSimplified} from './filter-form-simplified';
import { IATGauge } from './graphs/dscore-gauge';
import Mustache from 'mustache';
import ReactMarkdown from 'react-markdown';
import Typography from '@mui/material/Typography';
import { fetchText } from '../providers/fetch';


interface FilterPageProps{
    onApplyFilter: ()=> void;
    
}

// const markdown = `
// {{#hascomparedata}}
// Ваш резултат се **{{severityDelta}}** разликује од резултата особа {{#gender}}{{gender}} пола {{/gender}}{{#from_country}}из {{from_country}}{{/from_country}}{{#ageRange}} узраста {{ageRange}} година, {{/ageRange}} {{#citySize}}пореклом {{citySize}},{{/citySize}} {{#edu}}{{edu}},{{/edu}}{{#finance}} {{finance}} својим финансијским стањем{{/finance}} које су радиле овај тест.
// {{/hascomparedata}}

// {{#speedDelta}}_Ваше просечно време реакције је **{{speedDelta}}** у односу на просек испитаника вашег узраста._{{/speedDelta}}

// {{^hascomparedata}}
// За изабране критеријуме немамо довољан број резултата да бисмо могли да ваш резултат упоредимо с њима.
// {{!Пробајте да избором опција филтера мало проширите критеријуме за претрагу.}}
// {{/hascomparedata}}
// `;

export const FilterPage = ({onApplyFilter}: FilterPageProps) => {
    const tl = useTransliteration();
    const cfg = useRecoilValue(currConfigObject);
    const log = useRecoilValue(logEntry);
    const stats = useRecoilValue(currStats);
    const [markdown, setMarkdown] = useState("");
    const replacer = useScoreReplacer();
    
    useEffect(()=>{
        fetchText("assets/md/filter-template.md").then((text) =>
        setMarkdown(text)
      );
    },[]);

    const text = Mustache.render(markdown, {...replacer});
    
    return (
    <Stack sx={{maxWidth: "lg", m: "auto"}}>
        <Box sx={{m: 1, alignItems: "stretch"}}>
        <ReactMarkdown
                children={tl(text)}
            />
        </Box>
        <Typography variant="caption" sx={{textAlign:"center"}}>{tl("На графикону велика казаљка означава ваш резултат, а испрекидана плава линија (ако постоји) означава резултат групе са којом се поредите.")}</Typography>
        <Stack direction="row" style={{alignItems: "stretch", justifyContent: "stretch", flexWrap: "wrap"}}>
            <Box sx={{ m: 1}} style={{flex: 1, justifySelf: "stretch", justifyContent: "center", minWidth: 300}}>
                <IATGauge dScore={log.d_score} catA={cfg.categories.catA.label.n} catB={cfg.categories.catB.label.n} marker={stats.averageDScore}/>
            </Box>
            <Box style={{flex: 1, justifySelf: "stretch", minWidth: 300}}>
                <FilterFormSimplified testId={cfg.id} onApplyFilter={onApplyFilter}/>
            </Box>
        </Stack>
    </Stack>
    );
}