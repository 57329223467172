import XLSX from "exceljs";
import { saveAs } from "file-saver";

import { IATLogEntry, IATResponse } from "../iat-types";
import { fetchJson } from "./fetch";
import { READ_LOGS_FOR_EXCELL_EXPORT } from "../constants";

type LogEntry = Omit<IATLogEntry, "date"> & { test_date: Date | string | null }
type AnswerEntry = Omit<IATResponse, "date"> & { test_date: Date | string | null }

interface LogResponse {
  name: string;
  data: LogEntry[];
  answers: AnswerEntry[];
}

/*
// Excel date handling
const timestamp2exceldate = (t?: number) => t ? 25569 + t/86400000 : "";
const timestamp2string = (t: number = 0) => new Date(t).toISOString();
*/

function makeHeaders(sheet: XLSX.Worksheet) {
  const header = sheet.getRow(1);

  header.font = { bold: true, size: 12 };
  header.height = 20;
  header.alignment = { vertical: "middle" };
  header.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "DDDDDD" }
  };
  sheet.views = [{ state: "frozen", ySplit: 1 }];
  sheet.getColumn("test_date").width = 15;

  sheet.autoFilter = {
    from: { row: 1, column: 1 },
    to: { row: 1, column: sheet.columnCount }
  };
}


export const xlsxExport = async (
  cfgId: string,
  fetchAnswers: boolean = true
) => {

  let url = READ_LOGS_FOR_EXCELL_EXPORT + cfgId;
  if (fetchAnswers) url += "&answers";

  const response = await fetchJson( url) as LogResponse;

  const workbook = new XLSX.Workbook();
  const sheetName = response.name as string;
  const sheet = workbook.addWorksheet(sheetName);
  const answers = workbook.addWorksheet("answers");

  workbook.creator = "IATApp © studio@blur.rs";
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.title = `IAT report (${sheetName})`;

  const mappedData = response.data.map((x) => {
    if (x.test_date) x.test_date = new Date(x.test_date);
    return x;
  });

  const mappedAnswers = response.answers.map((x) => {
    if (x.test_date) x.test_date = new Date(x.test_date);
    return x;
  });

  console.log(mappedAnswers)

  sheet.columns = Object.keys(mappedData[0]).map((o) => ({ header: o, key: o }));
  sheet.addRows(mappedData);
  makeHeaders(sheet);

  if (mappedAnswers.length > 0){

    answers.columns = Object.keys(mappedAnswers[0]).map((o) => ({
      header: o,
      key: o
    }));
    
    answers.addRows(mappedAnswers);
    makeHeaders(answers);
  }


  const buff = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buff], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  });
  saveAs(blob, sheetName /*+ ".xlsx" */);

};
