//import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { TestPage } from "../components/question/test-page";
import { IATQuestion, IATResponse } from "../iat-types";

import { prepareTest } from "../prep-test";
import { currAnswers, currConfigObject, hashtagMap, logEntry, userSubmitted } from "../providers/app-state";
import { hasHashtags, isHashmapEmpty } from "../utils/hashtags";


const StartTest = () => {

    const navigate = useNavigate();
    
    const resetLogEntry = useResetRecoilState(logEntry);
    const resetAnswers = useResetRecoilState(currAnswers);
    const resetUserSubmitted = useResetRecoilState(userSubmitted);

    const config = useRecoilValue(currConfigObject);
    const hashes = useRecoilValue(hashtagMap);

    const [questions, setQuestions] = useState<IATQuestion[][]>( []);

    //const questions = useRecoilValue(questionsSelector);
    const setAnswers = useSetRecoilState(currAnswers);
    
    useEffect(()=>{
      resetAnswers();
      resetLogEntry();
      resetUserSubmitted();

      if (hasHashtags(config)){
        if (isHashmapEmpty(hashes)){
          // kada dolazimo ovde (back), hashes je prazan objekat. Treba preusmeriti na form…
          // _log("Hashes are empty", hashes);
          navigate("/"+config.id, {replace: true});
        }
        setQuestions(prepareTest(config, hashes))
      } else{
        setQuestions(prepareTest(config))
      }
    }, [config, hashes, resetAnswers, resetLogEntry, resetUserSubmitted, navigate])


    const onDone = (res: IATResponse[]) => {
        setAnswers(res);
        // TODO: write answer set to file on server
        navigate("/results", {replace: true});
      };
    

    return (
        <div>
            {/* <AppTitle title={tl(`Тест у току…`)} /> */}
            <TestPage questions={questions} onDone={onDone}/>
        </div>
    )
}

export default StartTest;