import { useCallback, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";

import Slide, { SlideProps } from "@mui/material/Slide";
import { useSpring, animated } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import { IATQuestion } from "../../iat-types";

import { useSnackbar } from "notistack";

import CardItem from "./card-item";
import { useSfx } from "../../providers/sound";
import { useTransliteration } from "../../providers/transliterate";
import { __debugInterval, __isDebugMode } from "../../providers/app-state";
import { useRecoilValue } from "recoil";
import { Typography } from "@mui/material";
import { FAB_MARGIN } from "../../constants";

export type AnimState = SlideProps["direction"] | "static";

export interface SwipableProps {
  onAnswer: (direction: AnimState, time: number) => void;
  question: IATQuestion;
}

export function SwipableCard({
  question,
  onAnswer,
}: SwipableProps) {
  const containerRef = useRef(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const tl = useTransliteration();
  const testingInterval = useRecoilValue(__debugInterval);
  const isDebug = useRecoilValue(__isDebugMode);

  const sfxWarn = useSfx("warning");
  const sfxErr = useSfx("buzzer");
  const sfxSwipe = useSfx("swipe");
  
  const [position, setPosition] = useState<AnimState>("static");
  
  const startTime = useRef<number>(0);
  const time = useRef(performance.now());

  const [dragProps, api] = useSpring(() => ({ x: 0, y: 0, scale: 1 }));

  const bind = useDrag(
    ({ event, active, movement: [x], velocity: [vx], direction: [dx] }) => {
      event.preventDefault();
      const newPosition = dx < 0 ? "left" : "right";
      if (vx > 0.9 && position !== newPosition) {
        setPosition(newPosition);
      }
      api.start({
        x: active ? x : 0,
        y: 0,
        scale: active ? 1.05 : 1,
        immediate: (k) => k !== "scale" && active
      });
    }
  );

  const isCardShown = useCallback(()=>position === "static", [position]) ;

  useEffect(() => {
    let isMounted = true;
    // Install keyboard handler
    const oldHandler = window.onkeydown;
    window.onkeydown = (e: KeyboardEvent) => {
      if (!isCardShown() || !isMounted) return;
      if (e.code === "KeyE" || e.code === "ArrowLeft") {
        setPosition("left");
      } else if (e.code === "KeyI" || e.code === "ArrowRight") {
        setPosition("right");
      }
    };
    return () => {
      isMounted = false;
      window.onkeydown = oldHandler;
      // Removed keyboard handler;
    };
  }, [isCardShown]);

  useEffect(() => {
    let isMounted = true;
    if (isDebug) {
      let testingTimer = setTimeout(() => {
        if (isMounted){
          setPosition(
            question.correct /*Math.random() < 0.5 ? "left" : "right" */
          );
        }
      }, testingInterval);
      return () => {
        isMounted = false;
        clearTimeout(testingTimer);
      }
    } else {
      let warningTimer = setTimeout(() => {
        if (!isMounted) return;
        enqueueSnackbar(tl("Ако радиш споро, тест неће дати употребљив резултат. Молим те да радиш што брже можеш!"), {
          variant: "warning",
          autoHideDuration: 5000,
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        sfxWarn();
      }, 6000);
      return () => {
        isMounted = false;
        clearTimeout(warningTimer);
      };
    }
  }, [question, testingInterval, isDebug, enqueueSnackbar, sfxWarn, tl]);

  const outDirection = (direction: AnimState): SlideProps["direction"] => {
    switch (direction) {
      case "left":
        return "right";
      case "right":
        return "left";
      default:
        return "up";
    }
  };

  const cardEntered = () => {
    startTime.current = performance.now();

    // // PATCH: add 100ms for the first render
    // if (question.round === 0 && question.question === 0){
    //   startTime.current += 100;
    // }
  };

  const cardExiting = () => {
    time.current = performance.now();

    closeSnackbar();
    if (position !== question.correct) {
      enqueueSnackbar(tl("Грешка"), {
        variant: "error",
        autoHideDuration: 1000,
        anchorOrigin: { vertical: "bottom", horizontal: "center" }
      });
      sfxErr();
    }else{
      sfxSwipe();
    }
  };

  const cardExited = () => {
    setPosition("static");
    onAnswer(position, Math.round(time.current - startTime.current));
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 3,
//        bgcolor: (theme) =>
//           theme.palette.mode === "light" ? "grey.100" : "grey.900",
        overflow: "hidden"
      }}
      ref={containerRef}
    >
      <animated.div {...bind()} style={{ ...dragProps, touchAction: "none" }}>
        <Box sx={{ display: "flex" }}>
          <Slide
            //appear={false}
            direction={outDirection(position)}
            in={isCardShown()}
            container={containerRef.current}
            timeout={{ enter: 5, exit: 250 }}
            onExit={cardExiting}
            onExited={cardExited}
            onEntered={cardEntered}
          >
            <Box>
              <CardItem payload={question.payload} />
            </Box>
          </Slide>
        </Box>
      </animated.div>
      {isDebug && (
      <Box style={{position: "absolute", bottom: FAB_MARGIN}}>
          <Typography variant="caption">{tl(`Серија ${question.round+1}, питање ${question.question+1}`)}</Typography>
      </Box>
      )
      }
    </Box>
  );
}
