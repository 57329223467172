import "./graphs.css";

import { useEffect, useRef, SVGProps } from "react";

import {
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Bar,
  ReferenceLine
} from "recharts";

import { useTheme } from "@mui/material";
import { Point } from "../../iat-types";
import { useTransliteration } from "../../providers/transliterate";

interface IATBarProps{
  data: Point[];
  marker? : Point;
  markerLabel?: Point;
}

export const DScorePlot = ({ data, marker }: IATBarProps) => {
  const theme = useTheme();
  const tl = useTransliteration();

  const { primary, secondary } = theme.palette;

  const total = useRef(0);

  useEffect(() => {
    if (data.length > 0) {
      let sum = 0;
      data.forEach((e) => (sum += e.y));
      total.current = sum;
    }
  });

  if (!data || data.length === 0) return null;

  const tooltipValueFormatter = (value: any /* name: any, props: any */) => {
    return ["(" + ((100 * value) / total.current).toFixed(1) + "%)", value];
  };

  const tooltipLabelFormatter = (label: number, props: any) => {
    return label.toFixed(2);
  };

  return (
    <ResponsiveContainer width="100%" aspect={2 / 1}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          domain={[-2,2]}
          tick={theme.typography.caption as SVGProps<SVGTextElement>}
          tickFormatter={(val) => val.toFixed(1)}
          dataKey="x"
          padding={{ left: 20, right: 20 }}
        >
        </XAxis>
        <YAxis tick={theme.typography.caption as SVGProps<SVGTextElement>}
            label={{
              position: "insideLeft",
            angle: -90,
            fill: theme.palette.text.secondary,
            value: tl("Број испитаника"),
            style: {...theme.typography.caption, textAnchor: "middle"}
            }}        
        />

        <Tooltip
          //labelStyle={theme.typography.body1}
          contentStyle={{backgroundColor: theme.palette.background.paper, borderRadius: 6}}
          cursor={{opacity: 0.2}}
          formatter={tooltipValueFormatter}
          separator=" "
          labelFormatter={tooltipLabelFormatter}
        />
        <Bar isAnimationActive={true} dataKey="y" fill={primary.main}>
          {/* {data.map((_, idx) => (
            <Cell
              key={idx}
              //onClick={idx===marked ? (e)=>_log(e) : ()=>{}}
              fill={idx === marked ? secondary.main : primary.main}
            />
          ))} */}
        </Bar>
        {marker && <ReferenceLine x={marker.x} strokeWidth={3} stroke={secondary.main} />}
        {/* {marker && (
          <ReferenceDot x={marker} y={0} label="" fill="green" r={6} />
        )} */}
        {/* <Line
          isAnimationActive={true}
          //unit={units}
          type="monotoneX"
          dataKey="y"
          stroke={"grey"}
          strokeWidth={2}
          //activeDot={{ r: 6 }}
          dot= {false}//{{ fill: BLUE, r: 2 }}
          /> */}
      </BarChart>
    </ResponsiveContainer>
  );
};
