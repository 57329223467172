import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useEffect, useRef } from "react";
import { useTheme, Divider, useMediaQuery } from "@mui/material";
import { useTransliteration } from "../../providers/transliterate";
import { IATHeaderRow } from "../../prep-test";


interface CategoryProps {
  items: IATHeaderRow[];
}

const Item = ({ title = "?", color = "grey", ...rest }) => {
  // const large = useMediaQuery("(min-width:700px)");
  const theme = useTheme();
  const large = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Typography variant={large ? "h4" : "h6"} sx={{ color: color, ...rest, userSelect: "none" }}>
      {title}
    </Typography>
  );
};

export function CategoryHeader({items}: CategoryProps) {
  const accentIndex = useRef(0);
  const theme = useTheme();

  const tl = useTransliteration();

  useEffect(() => {
    accentIndex.current = items.length > 1 ? 1 : 0;
  }, [items]);

  const accentColor = (i: number) => {
    if (i === accentIndex.current) {
      return theme.palette.success.main;
    }
    return theme.palette.text.primary;
  };

  const style = {
    width: "50%",
    maxWidth: 0,
    overflow: "hidden",
    textOverflow: "ellipsis"
    //whiteSpace: "no-wrap"
  };

  return (
    <Box sx={{paddingLeft: 2, paddingRight: 2 }}>
      <table width="100%">
        <tbody>
          {items.map((row, index) => (
            <tr key={index}>
              <td style={{ ...style, textAlign: "center" }}>
                {index > 0 && <Divider />}
                <Item title={tl(row.left)} color={accentColor(index)} />
              </td>
              {/* <td style={{ width: "5%" }}></td> */}
              <td style={{ ...style, textAlign: "center" }}>
                {index > 0 && <Divider />}
                <Item title={tl(row.right)} color={accentColor(index)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
}

