import { useRecoilValue, useResetRecoilState } from "recoil";
import { currConfigObject, hashtagMap } from "../providers/app-state";

import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
//import { useEffect, useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";

import Tooltip from "@mui/material/Tooltip";

//import Typography from "@mui/material/Typography";
import { useTransliteration } from "../providers/transliterate";
import AppTitle from "../layout/appbar";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../providers/firebase";
import { ADMIN_URL, FAB_MARGIN } from "../constants";

import TestListing from "../components/test-listing";
import { hasHashtags } from "../utils/hashtags";

import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";

import HashtagForm from '../components/hashtag-form';
import Typography from "@mui/material/Typography";

const SelectTest = ({selected=""}) => {
  const navigate = useNavigate();
  const tl = useTransliteration();
  const cfg = useRecoilValue(currConfigObject);
  
  const resetHashtags = useResetRecoilState(hashtagMap);

  const [user] = useAuthState(firebaseAuth);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [showUserEntry, setShowUserEntry] = useState(false);
  
  const handleStartTest = useCallback(
    () => {
      resetHashtags();
      if (hasHashtags(cfg)){
        setShowUserEntry(true);
      }else{
        navigate("/test", { replace: false })
      }
    },
    [cfg, navigate, resetHashtags],
  )
   

  useEffect(()=>{
    if (selected !== ""){
      handleStartTest();
    }
  }, [selected, handleStartTest]);


  const closeDialog = () => {
    setShowUserEntry(false);
    if (selected !=="") navigate(-1);
  }

  return (
    <>
      <AppTitle title="Изабери тест" noBackButton />
      <Box sx={{ flexDirection: "column", maxWidth: "md", m: "auto", p: 2 }}>
        <TestListing />
        <Button
          style={{ alignSelf: "center" }}
          variant="outlined"
          onClick={handleStartTest}
        >
          {tl("Почни тест")}
        </Button>
      </Box>
      {user && (
        <Tooltip title={tl("Иди на администраторску страну")}>
          <Fab
            style={{
              position: "absolute",
              bottom: FAB_MARGIN,
              right: FAB_MARGIN,
            }}
            color="secondary"
            onClick={() => navigate(ADMIN_URL)}
          >
            <SettingsIcon />
          </Fab>
        </Tooltip>
      )}

      <Dialog
        open={showUserEntry}
        onClose={closeDialog}
        fullScreen={fullScreen || selected !==""}
        fullWidth
      >
        <Box sx={{ maxWidth: "md", m: "auto" }}>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            {tl("Унесите вредности за персонализована поља")}
            <IconButton onClick={closeDialog} color="inherit">
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent >
          { cfg.intro && <Typography variant="body2">
            {tl(cfg.intro)}
          </Typography>}
          <div style={{height: 12}}></div>
          <HashtagForm
            cfg={cfg}
            onSubmit={(submitted) => {
              if (submitted){
                navigate("/test", { replace: false })
              }else{
                closeDialog();
              }
            }}
          />
        </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};

export default SelectTest;
