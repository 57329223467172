//import "./styles.css";
import { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

//import InstructionsDialog from "./instructions";
import { CategoryHeader } from "./category-header";
import { AnimState, SwipableCard } from "./swipable-slide";

import {IATQuestion, IATResponse} from '../../iat-types';
import { useTransliteration } from "../../providers/transliterate";
import { IATHeaderRow, useRoundHeaders } from "../../prep-test";
import { __isDebugMode } from "../../providers/app-state";
import { useRecoilValue } from "recoil";
import { InstructionDialog } from "../instructions";
import { Instructions } from "../key-icons";

type ResponseFunc = (r: IATResponse[]) => void;

interface TestProps {
  questions: IATQuestion[][];
  onDone: ResponseFunc;
}

// type IATHeader = {
//   left: string[];
//   right: string[];
// };

interface RoundProps {
  questions: IATQuestion[];
  header: IATHeaderRow[];
  onRoundEnd: ResponseFunc;
}

export interface QuestionProps {
  question: IATQuestion;
  onAnswer: ResponseFunc;
}

const DoQuestion = ({ question, onAnswer }: QuestionProps) => {
  //const startTime = useRef(Date.now());
  
  useEffect(() => {
   // startTime.current = Date.now();
    //_log(startTime.current);
  }, [question]);

  const onResult = (answer: AnimState, responseTime: number) => {
    const r: IATResponse = {
      round: question.round,
      question: question.question,
      dataLabel: question.dataLabel,
      questionIndex: question.questionIndex,
      isCorrect: question.correct === answer,
      responseTime: responseTime // - startTime.current
    };
    onAnswer([r]);
  };

  return (
    <SwipableCard
      onAnswer={onResult}
      question={question}
    ></SwipableCard>
  );
};

const DoRound = ({
  questions,
  onRoundEnd,
  header
}: RoundProps) => {
  const [question, setQuestion] = useState(0);
  const [responses, setResponses] = useState<IATResponse[]>([]);

  const isDone = () => question === questions.length - 1;

  const onAnswer = (r: IATResponse[]) => {
    const res = [...responses, ...r];
    setResponses(res);
    if (isDone()) {
      onRoundEnd(res);
      setResponses([]);
      setQuestion(0);
    } else {
      setQuestion((r) => r + 1);
    }
  };

  return (
    <Box
      sx={{display: "flex", flexDirection:"column", height: "100vh"}}
    >
      <Box sx={{display: "flex", flexDirection:"column", justifyContent: "center", flexGrow: 1}}>
        <CategoryHeader items={header} />
      </Box>
      <Box sx={{display: "flex", flexGrow: 3}}>
        <DoQuestion question={questions[question]} onAnswer={onAnswer} />
      </Box>
      <Box sx={{display: "flex", flexGrow: 2}}>
      <Instructions/>
      </Box>

    </Box>
  );
};

const DoTest = ({ questions, onDone }: TestProps) => {
  const [round, setRound] = useState(0);
  const [responses, setResponses] = useState<IATResponse[]>([]);
  const [showDialog, setShowDialog] = useState(true);

  const isDebugMode = useRecoilValue(__isDebugMode);

  let headers = useRoundHeaders(round);

  useEffect(() => {
      if (!isDebugMode) {
        setShowDialog(true);
      }
  }, [round, isDebugMode]);

  const isDone = () => round === questions.length - 1;

  const onRoundDone = (r: IATResponse[]) => {
    const res = [...responses, ...r];
    setResponses(res);
    if (isDone()) {
      onDone(res);
      setResponses([]);
    } else {
      setRound((r) => r + 1);
    }
  };
  return (
    <>
    <InstructionDialog round={round} open={showDialog } onClose={() => setShowDialog(false)} />

      {!showDialog && (
        <DoRound questions={questions[round]} header={headers} onRoundEnd={onRoundDone} />
      )}
    </>
  );
};

export function TestPage({ questions, onDone }: TestProps) {
  const [done, setDone] = useState(false);

  const tl = useTransliteration();

  const onLocalDone = (res: IATResponse[]) => {
    setDone(true);
    onDone(res);
    // Calculate results and write them to recoil store or wherever
    // _log(res);
  };

  return (
    <Stack spacing={3}>
      {done ? (
        <>
          <Typography variant="h3">{tl("Тест је завршен")}</Typography>
          <Button autoFocus onClick={() => setDone(false)}>
           {tl("Понови")}
          </Button>
        </>
      ) : (
          <DoTest questions={questions} onDone={onLocalDone} />
      )}
    </Stack>
  );
}
