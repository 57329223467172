import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
//import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { firebaseAuth } from "../providers/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { useTransliteration } from "../providers/transliterate";

import { useSnackbar } from "notistack";
import { ARCHIVE_DB } from "../constants";
import DeleteIcon from "@mui/icons-material/Delete";
import BackupIcon from "@mui/icons-material/Backup";
import RestoreIcon from "@mui/icons-material/Restore";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Chip from "@mui/material/Chip";
import ListItemText from "@mui/material/ListItemText";
import { fetchWithError } from "../providers/fetch";
import { humanFileSize } from "../utils/file-sizes";
import { useResetRecoilState } from "recoil";
import { availableConfigs } from "../providers/app-state";
import { ActionPanel } from "../components/action-panel";

interface IBackup {
  name: string;
  fullName?: string;
  size: number;
  modified: number;
}

async function archiveApi(cmd: string, name?: string) {
  let c = `?cmd=${cmd}`;
  if (name) c += `&name=${name}`;
  let res = await fetchWithError(ARCHIVE_DB + c);
  if (!res.ok) {
    console.error(res.statusText);
    alert(res.statusText);
  }
  return res.clone();
}

async function loadBackupList() {
  const res = await archiveApi("list");
  if (res.ok) {
    const list = (await res.json()) as IBackup[];
    return list.sort((a, b) => b.modified - a.modified);
  }
  return [];
}

export default function BackupRestore() {
  const navigate = useNavigate();
  const tl = useTransliteration();

  const [user, loading] = useAuthState(firebaseAuth);

  const [backups, setBackups] = useState<IBackup[]>([]);
  const [currentBackup, setCurrentBackup] = useState(0);

  const [busy, setBusy] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const reloadConfigs = useResetRecoilState(availableConfigs);

  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/login", { replace: true });
  }, [user, loading, navigate]);

  useEffect(() => {
    setBusy(true);
    loadBackupList().then((res) => {
      setBackups(res);
      setBusy(false);
    });
  }, []);

  const friendlyTime = (timestamp: number) => {
    const locale = tl("Ћ") === "Ћ" ? "sr-Cyrl" : "sr-Latn";
    const options = {
      weekday: "long",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
    } as const;
    const date = new Date(timestamp);
    return date.toLocaleDateString(locale, options);
  };

  const makeBackup = async () => {
    setBusy(true);

    const d = new Date();
    const id = `Backup ${d.getFullYear()}-${
      d.getMonth() + 1
    }-${d.getDate()}.db`;

    const res = await archiveApi("backup", id);
    if (res.ok) {
      enqueueSnackbar(tl(`Направљена је нова резервна копија базе (${id})`), {
        variant: "info",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
      });
      setBackups(await loadBackupList());
      setCurrentBackup(0);
    }
    setBusy(false);
  };

  const commitRestore = async (id: string) => {
    setBusy(true);

    let res: Response;
    try {
      res = await archiveApi("restore", id);
      if (res.ok) {
        enqueueSnackbar(tl(`Подаци су враћени из резервне копије (${id}).`), {
          variant: "info",
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
        });
        reloadConfigs();
      }
    } catch (err) {
      console.warn(err);
      enqueueSnackbar(tl(`Резервна копија (${id}) није исправна!`), {
        variant: "error",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
      });
    } finally {
      setBusy(false);
    }
  };

  const restoreBackup = (name: string) => {
    const key = enqueueSnackbar(
      <div>
        <b>{tl("ПАЖЊА:")}</b>
        <br />
        {tl(
          `Подаци у бази ће бити замењени подацима из резервне копије '${name}'. Ова акција не може да се поништи.`
        )}{" "}
        <br />
        {tl("Да ли сте сигурни?")}
      </div>,
      {
        autoHideDuration: 10000,
        variant: "warning",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        action: (
          <>
            <Button
              color="inherit"
              startIcon={<RestoreIcon />}
              onClick={async () => {
                closeSnackbar(key);
                await commitRestore(name);
              }}
            >
              {tl("Ок")}
            </Button>
            <Button color="inherit" onClick={(e) => closeSnackbar(key)}>
              {tl("Откажи")}
            </Button>
          </>
        ),
      }
    );
  };

  const vacuumDatabase = async () => {
    setBusy(true);
    const res = await archiveApi("vacuum");
    if (res.ok) {
      enqueueSnackbar(tl(`База података је оптимизована`), {
        variant: "info",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
      });
    }
    setBusy(false);
  };

  const commitDelete = async (id: string) => {
    setBusy(true);
    const res = await archiveApi("delete", id);
    if (res.ok) {
      enqueueSnackbar(tl(`Резервна копија је обрисана`), {
        variant: "info",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
      });
      setBackups(await loadBackupList());
      setCurrentBackup(0);
      reloadConfigs();
    }
    setBusy(false);
  };

  const deleteBackup = (name: string) => {
    const key = enqueueSnackbar(
      <div>
        <b>{tl("ПАЖЊА:")}</b>
        <br />
        {tl(
          "Резервна копија ће бити избрисана. Ова акција не може да се поништи."
        )}{" "}
        <br />
        {tl("Да ли сте сигурни?")}
      </div>,
      {
        autoHideDuration: 10000,
        variant: "warning",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        action: (
          <>
            <Button
              color="inherit"
              startIcon={<DeleteIcon />}
              onClick={async () => {
                closeSnackbar(key);
                await commitDelete(name);
              }}
            >
              {tl("Обриши")}
            </Button>
            <Button color="inherit" onClick={(e) => closeSnackbar(key)}>
              {tl("Откажи")}
            </Button>
          </>
        ),
      }
    );
  };
  
  const Actions = () => {
    const name = backups[currentBackup]?.name || "";

    return (
      <Box
        sx={{ m: 1 }}
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "stretch",
        }}
      >
        <LoadingButton
          sx={{ m: 2 }}
          size="small"
          variant="contained"
          disabled={busy || backups.length === 0}
          loading={busy}
          startIcon={<RestoreIcon />}
          onClick={() => {
            restoreBackup(name);
          }}
        >
          {tl("Врати податке")}
        </LoadingButton>
        <LoadingButton
          sx={{ m: 2 }}
          size="small"
          variant="contained"
          color="error"
          disabled={busy || backups.length === 0}
          loading={busy}
          startIcon={<DeleteIcon />}
          onClick={() => {
            deleteBackup(name);
          }}
        >
          {tl("Обриши")}
        </LoadingButton>
        <LoadingButton
          sx={{ m: 2 }}
          size="small"
          variant="contained"
          disabled={busy}
          loading={busy}
          startIcon={<BackupIcon />}
          onClick={() => {
            makeBackup();
          }}
        >
          {tl("Направи Нови")}
        </LoadingButton>

        <LoadingButton
          sx={{ m: 2 }}
          size="small"
          variant="contained"
          disabled={busy}
          loading={busy}
          startIcon={<SettingsSuggestIcon />}
          onClick={() => {
            vacuumDatabase();
          }}
        >
          {tl("Оптимизуј базу")}
        </LoadingButton>
      </Box>
    );
  };

//  const actionRef = useRef<HTMLDivElement>(null);
  
  if (!user) return null;

  return (
    <>
      <ActionPanel>
        <Actions />
      </ActionPanel>
      <Container maxWidth="lg">
        <List sx={{ width: "100%" }}>
          {backups.map((item, idx) => (
            <ListItem
              button
              key={idx}
              selected={currentBackup === idx}
              onClick={() => setCurrentBackup(idx)}
              secondaryAction={
                <Chip
                  label={humanFileSize(item.size)}
                  color="primary"
                  variant={currentBackup === idx ? "filled" : "outlined"}
                />
              }
            >
              <ListItemIcon>
                <CloudDoneIcon
                  color="primary"
                  style={{ opacity: currentBackup === idx ? 1 : 0 }}
                />
              </ListItemIcon>
              <ListItemText
                primary={tl(item.name)}
                secondary={tl(friendlyTime(item.modified))}
              />
            </ListItem>
          ))}
        </List>
      </Container>
    </>
  );
}
