//import Box from "@mui/material/Box";
//import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import useTheme from "@mui/material/styles/useTheme";
import { SVGProps } from "react";
import { FAB_SIZE } from "../constants";
import { useSnackbar } from "notistack";
import { useTransliteration } from "../providers/transliterate";
import { isTouchDevice } from "../utils/is-touch";

const defaultSvgProps: Partial<SVGProps<SVGSVGElement>>  = {
  stroke: "gray",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  fill: "none",
  strokeWidth: 3
}

const mergeProps = (props: SVGProps<SVGSVGElement>):SVGProps<SVGSVGElement> => {
  return {
    ...defaultSvgProps,
    ...props,
  }
}

export const IconKeysRight = (props: SVGProps<SVGSVGElement>) => (

  <svg xmlns="http://www.w3.org/2000/svg" width={157} height={65} {...props}>
    <defs>
      <style>
        {
          ".cls-1,.cls-2{fill:transparent;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:4px}.cls-2{fill:none}"
        }
      </style>
    </defs>
    <g id="RightKeys" transform="translate(-1131.5 -500.5)"
        {...mergeProps(props)}
    >
      <g id="KeyRight" transform="translate(294 -8)">
        <rect
          id="Key"
          width={62}
          height={62}
          rx={11}
          transform="translate(931 510)"
        />
        <g id="Arrow" transform="rotate(180 491.418 271.38)">
          <path
            id="Line_9"
            data-name="Line 9"
            transform="translate(.242 10.835)"
            d="M32.283 0H0"
          />
          <path
            id="Path_2"
            data-name="Path 2"
            d="M10.926 21.67 0 10.835 10.926 0"
          />
        </g>
      </g>
      <g id="KeyI" transform="translate(203 -8)">
        <rect
          id="Key-2"
          data-name="Key"
          width={62}
          height={62}
          rx={11}
          transform="translate(930 510)"
        />
        <g id="I" transform="translate(942.979 520.946)">
          <path
            id="Line_1"
            data-name="Line 1"
            transform="translate(4.544)"
            d="M0 0v27.173"
          />
          <path
            id="Line_2"
            data-name="Line 2"
            transform="translate(0 27.173)"
            d="M0 0h9.088"
          />
          <path
            id="Line_3"
            data-name="Line 3"
            d="M0 0h9.088"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const IconKeysLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={157} height={65} {...props}>

    <g id="LeftKeys" transform="translate(-851.5 -500.5)"
    {...mergeProps(props)}
    >
      <g id="KeyE" transform="translate(15 -9)">
        <rect
          id="Key"
          width={62}
          height={62}
          rx={11}
          transform="translate(930 511)"
        />
        <g id="E" transform="translate(941.062 521.113)">
          <path
            id="Path_1"
            data-name="Path 1"
            d="M23.064 52.95H13.91a4.59 4.59 0 0 1-4.577-4.6V29.936a4.59 4.59 0 0 1 4.577-4.6h9.154"
            transform="translate(-9.333 -25.333)"
          />
          <path
            id="Line_6"
            data-name="Line 6"
            transform="translate(0 13.808)"
            d="M0 0h9.154"
          />
        </g>
      </g>
      <g id="KeyLeft" transform="translate(-77 -8)">
        <rect
          id="Key-2"
          data-name="Key"
          width={62}
          height={62}
          rx={11}
          transform="translate(930 510)"
        />
        <g id="Arrow" transform="translate(915.669 498.341)">
          <path
            id="Line_9"
            data-name="Line 9"
            transform="translate(25.569 33.405)"
            d="M31.762 0H0"
          />
          <path
            id="Path_2"
            data-name="Path 2"
            d="M36.08 44.151 25.331 33.4 36.08 22.659"
          />
        </g>
      </g>
    </g>
  </svg>
);


function InstructionKeys() {
    const theme = useTheme();   
    const stroke = theme.palette.text.primary;
  
    return (
      <Grid container spacing={0} sx={{flexGrow: 1}}>
        <Grid item xs={6} sx={{textAlign: "center"}}>
          <IconKeysLeft stroke={stroke} />
        </Grid>
        <Grid item xs={6} sx={{textAlign: "center"}}>
         <IconKeysRight stroke={stroke} />
        </Grid>
      </Grid>
    );
  }

  function InstructionTouch() {

    const { enqueueSnackbar /*, closeSnackbar */ } = useSnackbar();
    const tl = useTransliteration();
    
    const swipeHelp = () => enqueueSnackbar(tl("Прстом одвуци картицу лево или десно"), {
      variant: "info",
      autoHideDuration: 2000,
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
    });

    return(
      <Grid container spacing={0} /*sx={{flexGrow: 1}} */>
      <Grid item xs={12}
            sx={{ 
              textAlign: "center",
        left: 0, right: 0,
        marginLeft: "auto", 
        marginRight: "auto"
      }}
      onClick={swipeHelp}
      >
        <img style={{width: FAB_SIZE, height: FAB_SIZE}} src="assets/img/icSwipe.svg" alt="Swipe help"/>
      </Grid>
    </Grid>
  );
  }

  export function Instructions(){
    return isTouchDevice() ? <InstructionTouch/> : <InstructionKeys/>
  }