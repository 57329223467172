import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import LoadingButton from '@mui/lab/LoadingButton';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { firebaseAuth } from "../providers/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { useTransliteration } from "../providers/transliterate";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { availableConfigs, currConfigId,  } from "../providers/app-state";

import { UploadConfig } from "../providers/upload-config";

import { useSnackbar } from "notistack";
import { _log } from "../constants";
import { IATConfig, IAvailableConfig } from "../iat-types";
import TestListing from "../components/test-listing";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { deleteConfig, toggleConfigActive, writeIatConfig } from "../providers/iat-logs";
import { getAvailableConfigs } from "../utils/initialize";
import Stack from "@mui/material/Stack";
import { ActionPanel } from "../components/action-panel";
import { copyDirectLink } from "../utils/copy-link";

export default function AdminConfigs() {
  const navigate = useNavigate();
  const tl = useTransliteration();

  const [user, loading] = useAuthState(firebaseAuth);

  const [newConfig, setNewConfig] = useState<IATConfig|null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [warnings, setWarnings] = useState<string[]>([]);
  const [willOverwrite, setWillOverwrite] = useState(false);

  const reloadCurrent = useResetRecoilState(currConfigId);

  const [busy, setBusy] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  
  const [configs, setConfigs] = useRecoilState(availableConfigs);
  const current = useRecoilValue(currConfigId);
  
  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/login", { replace: true });
  }, [user, loading, navigate]);
  
  useEffect(()=>{
    const idList = new Set(configs.map(c => c.id));
    if (!newConfig) {
      setWillOverwrite(false);
      return;
    }
    const newId = newConfig.id;
    setWillOverwrite(idList.has(newId));

  }, [configs, newConfig]);


  const toggleActive = async (cfg?: IAvailableConfig) => {

    if (!cfg) return;
    let {id, active} = cfg;

    setBusy(true);
    if (active === 0)
      active = 1;
    else 
      active = 0;
    const res = await toggleConfigActive(id, active);
    if (!res.ok){
      console.error(res.statusText);
      alert(res.statusText);
    }else{
      // update state to reflect the change
      let refreshedConfig = configs.map(c=> c.id === id ? {...c, active: active} : c);
      setConfigs(refreshedConfig);
      enqueueSnackbar(
        tl(`Тест је ${active === 1 ? "активиран":"деактивиран"}`), 
        { variant: "info", anchorOrigin: {horizontal: "center", vertical: "bottom"}, }
      );
    }
    setBusy(false);
  }


const commitDelete = async (id: string) => {
  setBusy(true);
  const res = await deleteConfig(id);
  if (!res.ok){
    console.error(res.statusText);
    alert(res.statusText);
  }else{
    // update state to reflect the change
    reloadCurrent();
    enqueueSnackbar(
      tl(`Тест је обрисан`), 
      { variant: "info", anchorOrigin: {horizontal: "center", vertical: "bottom"}, }
      );
    setConfigs(await getAvailableConfigs());
  }
  setBusy(false);
}

const deleteTest = (cfg: IAvailableConfig) => {
  const key = enqueueSnackbar(
    <div>
      <b>{tl("ПАЖЊА:")}</b><br/>{tl("Тест ће бити избрисан, а с њим и сви његови резултати!")}<br/>{tl("Ова акција не може да се поништи.")} <br/>{tl("Да ли сте сигурни?")} 
    </div>,
    { autoHideDuration: 10000,
      variant: "error",
      anchorOrigin: {horizontal: "center", vertical: "bottom"},
      action: (<>
        <Button color = "inherit" startIcon={<DeleteIcon/>} onClick={async ()=>{closeSnackbar(key); await commitDelete(cfg.id);}}>{tl("Обриши")}</Button>
        <Button color = "inherit" onClick={(e)=>closeSnackbar(key)}>{tl("Откажи")}</Button>
        </>)
    }
  );
  }


  const addTest = async (cfg : IATConfig) => {

   // let res:Response;
    
    try{
      await writeIatConfig(cfg);
      enqueueSnackbar(
        tl(`Тест '${cfg.id}' је додат`), 
        { variant: "info", anchorOrigin: {horizontal: "center", vertical: "bottom"}, }
        );
        setConfigs(await getAvailableConfigs());
      }catch(err){
          console.error(err);
          enqueueSnackbar(
            tl(`Дошло је до грешке приликом додавања теста '${cfg.id}'.`), 
            { variant: "error", anchorOrigin: {horizontal: "center", vertical: "bottom"}, }
          );
      }finally{
        setNewConfig(null);
      }
  }

  const Actions = () => {

    const configFromList = configs.find(c=>c.id === current);
    
    if (!configFromList) return null;
    
    const active = configFromList.active;

    return (
      <Box
      sx={{ m: 1 }}
      style={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "stretch"
      }}
    >
      <LoadingButton 
        sx={{m:2}}
        variant={ active ? "contained" : "outlined"}
        size="small"
        disabled={busy}
        loading={busy}
        startIcon={  active ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon/>}
        onClick={()=>{toggleActive(configFromList)}}
        >
        {active ? tl("Деактивирај") : tl("Активирај")}
      </LoadingButton>
      <LoadingButton 
        sx={{m:2}}
        variant="contained"
        color="error"
        size="small"
        disabled={configs.length < 2 || busy}
        loading={busy}
        startIcon={<DeleteIcon/>}
        onClick={()=>{deleteTest(configFromList)}}
        >
        {tl("Обриши")}
      </LoadingButton>
      <UploadConfig
          onLoad={(c) => {
            if (c.valid && c.instance){
              setNewConfig(c.instance);
              _log(c.instance);
              setErrors([]);
              setWarnings(c.warnings || []);
            }else{
              setNewConfig(null);
              setErrors(c.errors || []);
            }
            //setErrors(undefined);
          }}
        />
      <Button 
        sx={{m:2}}
        variant="outlined"
        color="primary"
        size="small"
        disabled={busy}
        startIcon={<InfoIcon/>}
        onClick={()=>{navigate(`/testkey`, {replace: false})}}
        >
        {tl("Индекс/кључ…")}
      </Button>
      <Button 
        sx={{m:2}}
        variant="outlined"
        color="primary"
        size="small"
        disabled={busy}
        startIcon={<ContentCopyIcon/>}
        onClick={async () => {
          const s = await copyDirectLink(current);
          if (s===""){
            enqueueSnackbar(tl("Грешка при копирању линка"), { variant: "error", anchorOrigin: {horizontal: "center", vertical: "bottom"}});
          }else{
            enqueueSnackbar(tl("Линк  ") + s + tl("  копиран."), { variant: "info", anchorOrigin: {horizontal: "center", vertical: "bottom"}});
          }
        }
        }
        >
        {tl("Копирај линк")}
      </Button>
    </Box>
    );
  }

  if (!user) return null;

  return (
    <>
      <ActionPanel>
        <Actions/>
      </ActionPanel>
      <Container  maxWidth="lg">
      {(newConfig  || errors.length>0) && <Stack alignItems="center">
        {errors && <div><ul style={{color: "red"}}>{errors.map((e,i)=><li key={"e"+i}><code style={{color: "red"}}>{e}</code></li>)}</ul></div>}
        {warnings && <div><ul style={{color: "orange"}}>{warnings.map((e,i)=><li key={"w"+i}><code style={{color: "orange"}}>{e}</code></li>)}</ul></div>}
        {newConfig && <div><code>{tl("Фајл је исправан.")}</code></div>}
        {willOverwrite && <div><code style={{color: "red"}}>{tl(`Тест '${newConfig?.id}' већ постоји и биће замењен новим.`)}</code></div>}
         {newConfig && <Button 
            sx={{mt:1}}
            variant="contained" 
            onClick={async () => await addTest(newConfig)}
            >
          {tl(`${willOverwrite ? "Замени" : "Додај"} тест`)}
        </Button>
        } 
        
        { errors.length !==0 && <Button 
        sx={{mt:1}}
        variant="outlined" 
        onClick={()=>{
          setNewConfig(null);
          setErrors([]);
          setWarnings([]);
        }}
        >
      {tl(`Откажи`)}
    </Button>
        }
        </Stack>}
      <TestListing/>

      </Container>

    </>
  );
}




