import Tooltip from "@mui/material/Tooltip/Tooltip";
import { useNavigate } from "react-router-dom";
import { useTransliteration } from "../providers/transliterate";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from "@mui/material/IconButton/IconButton";

export const BackButton = () => {
    const tl = useTransliteration();
    const navigate = useNavigate();
    
    const goBack = () => {
        navigate(-1);
    }
    
    return (
       // TODO: how to know if history is empty? If empty, don't render the button (or make it disabled).
      <Tooltip title={ tl("Назад")}>
      <IconButton sx={{ mr: 2 }} onClick={goBack} color="inherit">
          <ArrowBackIcon />
      </IconButton>
      </Tooltip>
    );
  }