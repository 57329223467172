import Button from "@mui/material/Button";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";
import { logEntry, ageGroups, currFilter, currStats, emptyFilter, surveyMainOptions } from "../providers/app-state";
import { useTransliteration } from "../providers/transliterate";
import { useEffect, useMemo, useRef, useState } from "react";
import { IFilterProps, ITarget } from "../iat-types";
import Typography from "@mui/material/Typography";
import Tooltip from '@mui/material/Tooltip';

import CircularProgress from "@mui/material/CircularProgress";
import CenterBox from "../layout/centerbox";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import { citySizeLabels, educationLabels, financialLabels, genderMap, _log, /*_wait */ } from "../constants";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { participantAge } from "../utils/participant-age";

interface ICheckFilter {
  title: string;
  label: string;
  target: ITarget;
  value: string;
}


interface IFilterFormProps{
  testId: string;
  onApplyFilter: ()=> void;
}

export const FilterFormSimplified = ({testId, onApplyFilter}:IFilterFormProps) => {
  
  //const cfg = useRecoilValue(currConfigObject);
  const ageGroupValues = useRecoilValue(ageGroups);
  //const usedCountries = useRecoilValue(currUsedCountries);
  const log = useRecoilValue(logEntry);
  //const stats = useRecoilValue(currStats);
  //const [stats, setStats] = useState<IAveragesForFilterResponse>(emptyStats);
  // const [loading, setLoading] = useState(false);
  const stats = useRecoilValueLoadable(currStats);

  const surveyMain = useRecoilValue(surveyMainOptions);
  
  const [currentFilter, setCurrentFilter] = useRecoilState(currFilter);
  //const setCurrentStats = useSetRecoilState(currStats);
  
  const filter = useRef<IFilterProps>({...currentFilter});
  
  const setFilter = (f:IFilterProps) => {
    filter.current = f;
    setCurrentFilter(f);
  };

  const checkBoxes = useRef<ICheckFilter[]>([]); 
  const currAgeGroup = useMemo( () => {
    const age = participantAge(log);
    const group = ageGroupValues.find(g => age >= g.min && age <= (g.max || 999) );
    return group;
  }, [log, ageGroupValues])
  
  const groupId = ()=>{
    const g = currAgeGroup;
    return g ? g.id : "";
  }
  
  const groupLabel = () =>{
    const g = currAgeGroup;
    return g ? `${g.title} (${g.range})` : "?";
  }
  


  useEffect(()=>{
    const survey: ICheckFilter[] = [];
    if (surveyMain){
      survey.push({
        title: surveyMain.title,
        label: surveyMain.options.get(log.surveyMain || "") || "?", 
        target: "surveyMain",
        value: ''+log.surveyMain
      })
    }

    checkBoxes.current = [
      ...survey,
      {title: "Пол", label: genderMap.find(g=>log.gender===g.id)?.text || "gender?", target: "gender", value: log.gender},
      {title: "Узраст", label: groupLabel(), target: "ageGroupId", value: groupId()},
      {title: "Образовање", label: educationLabels.find(x=>x.id === ""+log.edu)?.text || "edu?", target: "edu", value: ""+log.edu},
      {title: "Задовољство финансијама", label: financialLabels.find(x=>x.id === ""+log.finance)?.text || "finance?", target: "finance", value: ""+log.finance},
      {title: "Порекло", label: citySizeLabels.find(x=>x.id === log.citySize)?.extra || "citySize?", target: "citySize", value: log.citySize},
    ];

  })
  
  const resetFilter = ()=> {
    setChecked(new Set<number>());
    setFilter({...emptyFilter})
  };

    const tl = useTransliteration();

    const [checked, setChecked] = useState<Set<number>>(new Set<number>());

    const handleSubmit = (s?: Set<number>) => {
      let r = {...emptyFilter};
      if (!s) s = checked;
      s.forEach((e) => r[checkBoxes.current[e].target] = checkBoxes.current[e].value);
      _log(r);
      setFilter(r);
    };
  
    const handleChange = (idx: number) => {
      let s = new Set(checked);
      if (s.has(idx)) {
        s.delete(idx);
      } else {
        s.add(idx);
      }
      setChecked(s);
      handleSubmit(s);
    };   

    const isLoading = () => stats.state === "loading";

    const testCount = () => {
      if (stats.state === "hasValue" && stats.contents.count > 0){
        return stats.contents.count;
      }
      return "—";
    }

return (<Stack spacing={1} sx={{pt: 1}} style={{maxWidth: 360, margin: "auto"}}>
 
  <List dense sx={{ width: '100%', /* bgcolor: 'background.paper' */ }}>
      {checkBoxes.current.map((cb, index) => {
        const labelId = `checkbox-list-label-${cb}`;

        return (
          <ListItem
            key={index}
            alignItems="flex-start"
            // secondaryAction={
            //   checked.has(index) && <CheckCircle/>
            // }
          >
            <ListItemButton onClick={()=>handleChange(index)}>
              <ListItemIcon>
                <Checkbox
                  edge="end"
                  checked={checked.has(index)}
                  tabIndex={-1}
                  //disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} 
                  primary={tl(cb.title)}
                  primaryTypographyProps={{fontSize: "0.75rem", color: "primary"}}
                  secondary={tl(cb.label)}
                  //secondaryTypographyProps={{fontSize: "1.2rem"}}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>

    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>

    <Button
      //disabled={isLoading() || isNaN(stats.contents.averageDScore)}
      variant="outlined"
      size="small"
      onClick={ () => {
        resetFilter();
      }}
    >
      {tl("Све")}
    </Button>

    <CenterBox style={{minWidth: "10%"}}>
    
    {isLoading() ? 
        <CircularProgress size="1rem" />
        : 
      <Tooltip arrow title={tl("Укупан број тестова који одговарају изабраним критеријумима.")}>
        <Typography variant="body1" style={{textAlign: "center", userSelect: "none"}}>{ testCount() }</Typography>
      </Tooltip>
      }
    </CenterBox>
    
    <Button
      //disabled={isLoading() || isNaN(stats.contents.averageDScore)}
      variant="contained"
      size="small"
      onClick={ async () => {
        handleSubmit();
        setFilter(filter.current);
        //setCurrentStats(stats);

        //TODO da li postoji bolji način da sačekamo da recoil završi svoje?
        setTimeout(()=>{onApplyFilter()}, 1000);
      }}
    >
      {tl("Ok")}
    </Button>

    </Stack>
  </Stack>
  );
}



