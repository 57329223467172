import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { ReactNode, useEffect, useRef, useState } from "react";
import { GET_CHART_DATA } from "../../constants";
import { Point } from "../../iat-types";
import CenterBox from "../../layout/centerbox";
import { useFetch } from "../../providers/fetch";
import { useTransliteration } from "../../providers/transliterate";
import CenteredSpinner from "../centered-spinner";
import { DScorePlot } from "./dscoreplot";
import { IATPie } from "./pie-chart";
import { ResponseTimeByAgePlot } from "./response-time-plot";
import { mockResponseTime } from "../../utils/delta-score";
import { useRecoilValue } from "recoil";
import { surveyMainOptions } from "../../providers/app-state";

const chartTypeNames = ["agerange", "device", "country", "age", "gender", "dscore", "survey"] as const;

export type ChartDataset = typeof chartTypeNames[number];

export interface IATChartProps{
    testId: string;
    title?: string;
    subheader?: string;
    kind: ChartDataset;
    type: "line" | "pie" | "bar";
    aggregate?: number;
    marker?: Point;
    markerLabel?: string;
    children?: ReactNode;
}

const mockAgeData = (data:Point[]):Point[] => {
  const res: Point[] = [...data];
  for(let a=18;a<100;a++){
    if (!res.find(p=>p.x === a))
      res.push({x: a, y: mockResponseTime(a) })
  }
  return res.sort((a,b)=> typeof a.x ==='string' || typeof b.x === 'string' ? 0 : a.x - b.x);
}

// export async function getChartData(testId:string, chart: string):Promise<Point[]>{
//     let resp = await fetchWithError(GET_CHART_DATA + `${testId}&chart=${chart}`);
//     let res = await resp.json() as Point[];
//     if (res?.length < 20 && chart === 'age'){
//       res = mockAgeData()
//     }
//     return res;
//   }

const aggregatePoints = (p: Point[] | undefined, count: number = 5, label="Остало") => {
    if (p===undefined) return undefined;
    if (p.length <=count) return Array.from(p);
    let total = p.map(e=>e.y).reduce((prev, cur)=>prev+cur,0);
    const r = Array<Point>();
    for(let i=0;i<count;i++){
        r.push(p[i]);
    }
    r.push({x:label, y: total - r.map(e=>e.y).reduce((prev, cur)=>prev+cur,0)})
    return r;
}

// const mapSurvey = (data: Point[], survey?: SurveyQuestion ):Point[] => {
//   return data.map(p=>({x: survey?.choices.find(c=>c.value === p.x)?.label || p.x, y:p.y}))
// }

export function Chart(props: IATChartProps) {

    const {testId, title, subheader, kind, type, aggregate, marker, markerLabel } = props;

    // const {surveyMain} = useRecoilValue(currConfigObject);

    const fetchData = useFetch<Point[]>( GET_CHART_DATA + `${testId}&chart=${kind}`);

   // const mappedData = useRef<Point[]>([]);
  
    const tl = useTransliteration();
    const surveyMain = useRecoilValue(surveyMainOptions);
    
    const [,setRedraw] = useState(0);

    if (fetchData.error) throw Error(fetchData.error.message);

    const data = useRef(fetchData.data);

    const mapSurvey = (data: Point[], survey: {title: string, options: Map<number|string, string>} | null ):Point[] => {
      return data.map(p=>({x: survey?.options.get(p.x.toString()) || p.x, y:p.y}))
    }

    useEffect(()=>{
      if (aggregate && aggregate>0 ){
        data.current = aggregatePoints(fetchData.data,aggregate)
      }else{
        data.current = fetchData.data;
        if (data.current && kind === 'age'){
          // dopuni nedostajuće vrednosti prosečnih odziva na osnovu formule
          data.current = mockAgeData(data.current);
        }
      }
      
      if (kind==="survey"){
        data.current = mapSurvey(data.current || [], surveyMain);
     }
      setRedraw( old=>old+1);
    },[aggregate, kind, testId, fetchData.data, surveyMain])

    const hasTitle = () => title || subheader;
    
    //if (!fetchData.data) return <CenteredSpinner/>

    return (
      <Grid item xs={12} md={6} xl={4} sx={{flexGrow: 1}}>
        <Card elevation={4} sx={{height:"100%"}}>
        { hasTitle() && (
            <CardHeader
                title={tl(title)}
                subheader={ tl(subheader) }
        />)
        }

        <CardContent>
          {!data.current && <CenteredSpinner/>}
          {data.current && data.current.length === 0 && <CenterBox>{tl("Нема података")}</CenterBox>}
          { type==='pie' && <IATPie data={data.current || []} />}
          { type==='line' && <ResponseTimeByAgePlot data={data.current || []} marker={marker} markerLabel={markerLabel} />}
          { type==='bar' && <DScorePlot data={data.current || []} />}
        </CardContent>
        <CardActions>
          {props.children}
      </CardActions>
      </Card>
      </Grid>
    )
}