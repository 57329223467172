// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { useTheme, useMediaQuery } from "@mui/material";
import { forwardRef, SyntheticEvent } from "react";
import { useTransliteration } from "../../providers/transliterate";
import { isUrl } from "../../utils/is-url";

interface CardItemProps {
  payload: string;
  size?: number;
}

const CardItem = forwardRef((props: CardItemProps, ref) => {
  
  const tl = useTransliteration();
  const theme = useTheme();
  const large = useMediaQuery(theme.breakpoints.up('sm'));
  
  const { payload, size =  large ? 400 : 250 } = props;

  return (
    <Card
      //@ts-ignore
      ref={ref}
      sx={{ padding: .7, width: size, height: size, borderRadius: "8%" }}
      elevation={5}
      // prevent context menu
      onContextMenu={(e:SyntheticEvent)=>{e.preventDefault()}}
    >
      {!isUrl(payload) ? (
        <svg width="100%" height="100%" viewBox="0 0 100 100" >
        <text 
              x="50%" y="53%"
              textAnchor="middle"
              fontFamily={theme.typography.fontFamily}
              fill={theme.palette.text.primary}
              opacity={0.65}
              style={{userSelect: "none"}}
              >
           {tl(payload)}
         </text>
       </svg>
        // <Box
        //   display="flex"
        //   alignItems="center"
        //   justifyContent="center"
        //   height="100%"
        // >
        //   <Typography variant="h3" 
        //   // prevent text selection
        //   sx={{userSelect: "none"}}
        //   >
        //     {tl(payload)}
        //   </Typography>
        // </Box>
      ) : (
        <CardMedia
          sx={{ m: 0, borderRadius: "7%", userSelect: "none" }}
          component="img"
          src={payload}
          style={{ objectFit: "fill", aspectRatio: "1/1" }}
          onError={(ev: any) => (ev.target.src = "/assets/img/default-image.svg")}
        />
      )}
    </Card>
  );
});

export default CardItem;
