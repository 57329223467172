import { useRecoilValue } from "recoil";
import { IATQuestion, IATConfig, IATCategories } from "./iat-types";
import { currConfigObject } from "./providers/app-state";
import { randomizeIndices } from "./utils/array-utils";
import { HashtagCategories, replaceHashtags } from "./utils/hashtags";

interface IATHeaderKeys {
  left: IATCategories[];
  right: IATCategories[];
}

export interface IATHeaderRow {
  left: string;
  right: string;
}

const roundKeys: IATHeaderKeys[] = [
  {left: ["catA"], right: ["catB"] },
  {left: ["cat1"], right: ["cat2"] },
  {left: ["catA", "cat1"], right: ["catB", "cat2"] },
  {left: ["catA", "cat1"], right: ["catB", "cat2"] },
  {left: ["catB"], right: ["catA"] },
  {left: ["catB", "cat1"], right: ["catA", "cat2"] },
  {left: ["catB", "cat1"], right: ["catA", "cat2"] },
];

// const roundKeys: RoundArrayColumns = [
//   [["catA"], ["catB"]],
//   [["cat1"], ["cat2"]],
//   [
//     ["catA", "cat1"],
//     ["catB", "cat2"],
//   ],
//   [
//     ["catA", "cat1"],
//     ["catB", "cat2"],
//   ],
//   [["catB"], ["catA"]],
//   [
//     ["catB", "cat1"],
//     ["catA", "cat2"],
//   ],
//   [
//     ["catB", "cat1"],
//     ["catA", "cat2"],
//   ],
// ];

const flipCoin = () => Math.random() < 0.5;

const rnd = (min: number, max: number) =>
  Math.trunc(Math.random() * (max - min) + min);

export const prepareTest = (cfg: IATConfig, tagMap?: HashtagCategories): IATQuestion[][] => {
  const res: IATQuestion[][] = [];

  for (let i = 0; i < cfg.questionsPerBlock.length; i++) {
    res.push(prepareBlock(cfg, i, tagMap));
  }
  return res;
};

const prepareBlock = (cfg: IATConfig, round: number, tagMap?: HashtagCategories): IATQuestion[] => {
  const res: IATQuestion[] = [];

  const l = roundKeys[round];
  const r = roundKeys[round];

  const {cat1, cat2, catA, catB} = cfg.categories;

  if (
    Math.min(
      cat1.items.length,
      cat2.items.length,
      catA.items.length,
      catB.items.length,
    ) !==
    Math.max(
      cat1.items.length,
      cat2.items.length,
      catA.items.length,
      catB.items.length,
    )
  ) {
    console.error("Sve liste stimulusa moraju biti jednakih dužina.");
    return [];
  }

  const tmpCategories = tagMap ? replaceHashtags(cfg, tagMap) : {...cfg.categories};

  const indices = randomizeIndices(tmpCategories.catA.items.length, cfg.questionsPerBlock[round]);

  let stimuli: IATCategories[], correct: "left" | "right";

  for (let i = 0; i < indices.length; i++) {
    if (flipCoin()) {
      correct = "left";
      stimuli = l.left;
    } else {
      correct = "right";
      stimuli = r.right;
    }
    let key = stimuli[rnd(0,stimuli.length)];
    let category = tmpCategories[key];
    let item = category.items[indices[i]];

    res.push({
      round: round,
      question: i,
      dataLabel: category.datalabel,
      questionIndex: indices[i],
      correct: correct,
      payload: item,
    });
  }
  //_log(res);
  return res;
}


export const useRoundHeaders = (round: number) => {

  const cfg = useRecoilValue(currConfigObject);
  
  const l = roundKeys[round].left;
  const r = roundKeys[round].right;

  if (l.length !== r.length) throw new Error("Round headers are not the same length.");

  const res : IATHeaderRow[] = Array(l.length);
  for (let i=0;i<l.length;i++){
    res[i] = {
      left:  cfg.categories[l[i]].label.n,
      right: cfg.categories[r[i]].label.n,
    }
  }
  return res;
}
