import "survey-core/defaultV2.min.css";

import themeJson from "./survey-theme.json";

//import { transliterateJSON } from "./i18n/transliterate-survey";
import "./i18n/rs-cyrl";
import "./i18n/rs-latn";

import { ITheme, Model, SurveyModel } from "survey-core";
import { Survey } from "survey-react-ui";
import { useCallback, useState } from "react";

import { appScript } from "../providers/transliterate";
import { useRecoilValue } from "recoil";
import { SurveyFields, validateResponseFields } from "./validate-response-fields";

//const survey = new Model(transliterateJSON(json));


interface SurveyProps{
    model: SurveyModel;
    onComplete: (response:SurveyFields)=>void;
}

function SurveyComponent(props:SurveyProps) {

const {model, onComplete} = props;

  const locale = useRecoilValue(appScript);

  const [survey] = useState<Model>(new Model(model));

//   useEffect( ()=>{},[]);

  survey.applyTheme(themeJson as ITheme);

  const handleSurveyCompletion = useCallback((sender: { data: any }) => {
    const results = sender.data;
    survey.getAllQuestions().forEach(function (question) {
      if (results[question.name] === undefined) {
        results[question.name] = null;
      }
    });
    const validator = validateResponseFields(results);
    if (validator.valid && validator.instance){
        onComplete(validator.instance);
        console.log(validator);
    }else{
        const err = `Polja ${validator.errors.join(", ")} ne postoje u anketi. Proverite konfiguraciju.`
        console.error(err);
        alert(err);
    }

  }, [onComplete, survey]);

  survey.onComplete.add(handleSurveyCompletion);
  
  return <Survey model={survey} locale={locale} />;
}

export default SurveyComponent;