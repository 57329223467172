import Ajv, {Schema} from 'ajv';
//import {validate, Schema, ValidationError, ValidatorResult} from 'jsonschema';
import {IATConfig,  IATCategoryNames} from '../../iat-types';

//import * as configSchema from  '../../../public/assets/config-schema.json'; //'./config-schema.json';
import { CONFIG_SCHEMA_URL } from '../../constants';
import { fetchJson } from '../fetch';

export interface ValidatorResult{
    valid: boolean;
    instance: IATConfig | null;
    errors?: string[];
    warnings?: string[];
}
const areItemsSameLength = (config: IATConfig) => {
    let l = new Set<number>();
    for(let c of IATCategoryNames){
        l.add(config.categories[c].items.length);
    }
    return l.size === 1;
}


export const validateConfig = async (config: IATConfig | string, schemaUrl: string = CONFIG_SCHEMA_URL):Promise<ValidatorResult> => {

    const ajv = new Ajv();
    let errors: string[] = [];

    var configObj: IATConfig;

    if (typeof config === 'string'){
        try {
            configObj = JSON.parse(config);
        } catch (err) {
            return {
                valid: false,
                errors: [`Error parsing JSON config: ${err}`],
                instance: null
            };
        }
    }else{
        configObj = config;
    }
    if (!configObj.questionsPerBlock){
        configObj.questionsPerBlock = [20, 20, 40, 40, 20, 40, 40];
    }

    const schema = await fetchJson(schemaUrl) as Schema;
    const validate = ajv.compile(schema)
    let res = validate(configObj);
    if (res){
        if (!areItemsSameLength(configObj)){
            res = false;
            errors.push("Item arrays are not the same length");
        }
    }else{
        const e = validate.errors?.map(e=>e.instancePath + " " + e.message);
        if (e) errors = errors.concat(e);
    }


    return {
        valid: res,
        instance: res ? configObj : null,
        errors: errors
    }
}


