//import testtext from '../test1400';

import { _log } from '../constants';
import {IATResponse} from '../iat-types';

const MAX_RESPONSE_TIME = 10000,
      MIN_VALID_RESPONSE_TIME = 300,
      MAX_FAST_ANSWERS_PERCENT = 0.1,
      ERROR_PENALTY_TIME = 600;

export namespace Stat{

    export const sum = (arr:number[]):number => arr ? arr.reduce( (prev, val)=>prev+=val, 0) : 0;

    export const mean = (arr:number[]):number => (!arr || arr.length===0) ? NaN : sum(arr)/arr.length;

    export const stdev = (arr:number[]): number => {
        if (!arr || arr.length === 0) return NaN;
        const m = mean(arr);
        const s = arr.map(x => (x-m)*(x-m) ).reduce((prev, val)=> prev+=val, 0);
        return Math.sqrt(s/arr.length);
    }
}

// ako je više od 10% odgovora imalo odziv manji od 300ms test je nevažeći
export const isTestValid = (res:IATResponse[], minTime = MIN_VALID_RESPONSE_TIME) => {
    if (!res || res.length === 0) return false;
    const rtBelow = res.filter(r=>r.responseTime<minTime).length;
    _log(`${rtBelow} answers (${Math.round(rtBelow/res.length*100)}%) is below ${minTime}ms`)
    return rtBelow/res.length < MAX_FAST_ANSWERS_PERCENT;
}

export const filterInvalidResponses = (responses: IATResponse[], maxTime = MAX_RESPONSE_TIME) => responses.filter( r => r.responseTime<maxTime)

export const getBlock = (res: IATResponse[], block:number) : IATResponse[] => res.filter(r=>r.round === block);

export const calcDScore = (res:IATResponse[]):number => {
    if (!isTestValid(res)) return NaN;

    let cleanupres = filterInvalidResponses(res);
    let block3 = getBlock(cleanupres, 2).map(x=>x.responseTime);
    let block4 = getBlock(cleanupres, 3).map(x=>x.responseTime);
    let block6 = getBlock(cleanupres, 5).map(x=>x.responseTime);
    let block7 = getBlock(cleanupres, 6).map(x=>x.responseTime);
    const sd36 = Stat.stdev([...block3, ...block6]);
    const sd47 = Stat.stdev([...block4, ...block7]);
    
    // adjust response times for incorrect answers
    block3 = getBlock(cleanupres, 2).map(x=>x.isCorrect ? x.responseTime : x.responseTime+ERROR_PENALTY_TIME);
    block4 = getBlock(cleanupres, 3).map(x=>x.isCorrect ? x.responseTime : x.responseTime+ERROR_PENALTY_TIME);
    block6 = getBlock(cleanupres, 5).map(x=>x.isCorrect ? x.responseTime : x.responseTime+ERROR_PENALTY_TIME);
    block7 = getBlock(cleanupres, 6).map(x=>x.isCorrect ? x.responseTime : x.responseTime+ERROR_PENALTY_TIME);
    const m3 = Stat.mean(block3);
    const m4 = Stat.mean(block4);
    const m6 = Stat.mean(block6);
    const m7 = Stat.mean(block7);
    return ((m6-m3)/sd36 + (m7-m4)/sd47) / 2;
}

export const calcAverageResponseTime = (res:IATResponse[]):number => {
   // if (!isTestValid(res)) return NaN;
    let cleanupres = filterInvalidResponses(res);
    return Stat.mean( cleanupres.filter(x=> x.isCorrect).map(x => x.responseTime) );
}

// let r: IATResponse[] = [];
// const lines = testtext.split("\n");
// lines.forEach(l => {
//     let a = l.split(",");
//     r.push({round: parseInt(a[0]), question: parseInt(a[1]), dataLabel: a[2], itemNumber: parseInt(a[3]), isError: a[4]!=='0', responseTime: parseInt(a[5]) })
// });
// _log(`d=${calcDScore(r)}`);