import Paper from "@mui/material/Paper";
import { ReactNode, useRef } from "react";
import { useResizeDetector } from 'react-resize-detector';


interface PanelProps{
    children: ReactNode;
}


export const ActionPanel = ({ children }: PanelProps) => {
   
  const targetRef = useRef<HTMLDivElement>(null);
  const {height} = useResizeDetector({targetRef});

  return (
    <>
      <Paper
        ref={targetRef}
        sx={{
          m: 0, p: 0,
          position: "fixed",
          left: 0,
          right: 0,
          zIndex: (theme) => theme.zIndex.modal,
        }}
       elevation={3}
      >
        {children}
      </Paper>
      <div style={{height: height}}></div>
    </>
  );
};