import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { IATLogEntry } from "../iat-types";
import AppTitle from "../layout/appbar";
import {
  currAnswers,
  currConfigObject,
  currStats,
  logEntry,
  surveyMainOptions,
  userSubmitted
} from "../providers/app-state";
import { responsesToCsv } from "../providers/csv-convert";
import { useTransliteration } from "../providers/transliterate";
import {
  calcAverageResponseTime,
  calcDScore,
  isTestValid
} from "../utils/stats";

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton, Typography, useTheme
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import { IATGauge } from "../components/graphs/dscore-gauge";
import { ScoreText } from "../components/score-to-text";
import { fetchText } from "../providers/fetch";

import CenteredSpinner from "../components/centered-spinner";
import { FilterPage } from "../components/filter-page";
import { Chart } from "../components/graphs/chart-component";
import { _log } from "../constants";
import CenterBox from "../layout/centerbox";
import { participantAge } from "../utils/participant-age";
import UserData from "./userdata";

const ResultsPage = () => {
  const tl = useTransliteration();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const answers = useRecoilValue(currAnswers);
  const [log, setLog] = useRecoilState(logEntry);
  const cfg = useRecoilValue(currConfigObject);
  const stats = useRecoilValue(currStats);
  const survey = useRecoilValue(surveyMainOptions);

  const [detailedReport, setDetailedReport] = useRecoilState(userSubmitted); 

  const [showUserEntry, setShowUserEntry] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  

  const [markdown, setMarkdown] = useState<string | null>(null);

  const [valid, setValid] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isTestValid(answers)) {
      setValid(false);
      fetchText("assets/md/nevazeci-test.md").then((text) =>
        setMarkdown(text)
      );
    } else {
      fetchText("assets/md/za-vise-info.md").then((text) =>
        setMarkdown(text)
      );

      const d = Math.round(calcDScore(answers) * 1000) / 1000;
      const art = Math.round(calcAverageResponseTime(answers));

      let newLog: IATLogEntry = {
        ...log,
        d_score: d,
        art: art,
      };

      setLog(newLog);
      _log(responsesToCsv(answers));
    }
    // eslint-disable-next-line
  }, [answers]);

  const GotoHome = () => (
    <Button
      //variant="contained"
      onClick={() => {
        navigate("/", { replace: true });
      }}
    >
      {tl("На почетак")}
    </Button>
  );

  const PrintButton = () => (
    <Button
      variant="outlined"
      onClick={() => {
        window.print();
      }}
    >
      {tl("Штампа")}
    </Button>
  );

  const closeForm = useCallback( ()=>{
    setShowUserEntry(false);
    _log("Closed form");
  }, []);

  const openForm = ()=>{
    setShowUserEntry(true);
    _log("Opened form");
  }

  const age = participantAge(log);

  if (!valid)
    return (
      <CenterBox sx={{ p: 3 }}>
        <Card sx={{ p: 2, maxWidth: "md", borderRadius: 6 }} elevation={6}>
          {/* Prikaz poruke o nevalidnosti rezultata */}
          <CardContent>
            {markdown ? (
              <ReactMarkdown children={tl(markdown || "…")} />
            ) : (
              <CenteredSpinner />
            )}
          </CardContent>
          <CardActions>
            <GotoHome />
          </CardActions>
        </Card>
      </CenterBox>
    );

  return (
    <Box>
      <Dialog
        open={showUserEntry}
        onClose={closeForm}
        fullScreen={fullScreen}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            {tl("Представите нам се…")}
            <IconButton onClick={closeForm} color="inherit">
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ marginTop: 4 }}>
        {cfg.resultNote && cfg.resultNote.length>0 &&
            <Card elevation={5}><CardHeader title={tl("Напомена")}></CardHeader><CardContent>{tl(cfg.resultNote)}</CardContent></Card>
          }
          <UserData
            onSubmit={(submitted) => {
              closeForm();
              setDetailedReport(submitted);
            }}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        keepMounted
        open={showFilter}
        onClose={() => setShowFilter(false)}
        fullScreen
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            {tl("Поређење са одабраним групама")}
            <IconButton onClick={() => setShowFilter(false)} color="inherit">
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <FilterPage onApplyFilter={() => setShowFilter(false) } />
          {/* <FilterForm testId={cfg.id} onApplyFilter={()=>setShowFilter(false)} /> */}
        </DialogContent>
      </Dialog>

      <AppTitle title={tl("Резултати")} noBackButton />

      <Stack sx={{ maxWidth: "md", m: "auto", p: 2 }} spacing={2}>
        <Card
          className="printableContainer"
          elevation={6}
          sx={{ maxWidth: "sm", minWidth: "sm" }}
          style={{ margin: "auto", borderRadius: "24px" }}
        >
          <CardContent>
            <IATGauge
              dScore={log.d_score}
              catA={cfg.categories.catA.label.n}
              catB={cfg.categories.catB.label.n}
              marker={detailedReport ? stats.averageDScore : undefined}
            />
          </CardContent>
        </Card>

          <ScoreText detailedReport={detailedReport} templateUrl="assets/md/results-template.md" />

        {/* Za više informacija... */}
        {!detailedReport && <ReactMarkdown children={markdown ? tl(markdown) : ""} />}

        {detailedReport && !showFilter && (
          <Stack>
          {/* <Typography variant="body1" sx={{textAlign:"center"}}>{tl("За почетак детаљнијег поређења, погледајте графикон брзине ваших реакција у односу на друге.")}</Typography> */}
          <Box className="printableContainer">
            <Chart
              testId={cfg.id}
              // title={tl("Брзина реакције")}
              // subheader={tl("За почетак детаљнијег поређења, погледајте графикон брзине ваших реакција у односу на друге.")}
              kind="age"
              type="line"
              marker={{ x: age, y: log.art }}
              markerLabel={tl("Ваш резултат")}
            />
            {survey && <Chart type="pie" kind="survey" testId={cfg.id} title={survey.title} subheader={tl("Упоредите ваш одговор на прво, главно питање из анкете са одговорима свих учесника")}></Chart>}
          </Box>
          </Stack>
        )}

        {detailedReport && (
            <>
            {/* <Typography variant="caption" sx={{textAlign:"center"}}>{tl("Резултати поређења с другима биће приказани у складу с вашим избором група за поређење")}</Typography> */}
            <Typography variant="body2" sx={{textAlign:"center"}}>{tl("У наставку преко филтера можете одабрати једну или више група испитаника с којима ћете се поредити. Притиском на дугме ОК чувате приказ резултата, а заједно са текстуалним делом извештаја можете га одштампати притиском на одговарајуће дугме.")}</Typography>
            <Button
              variant="contained"
              onClick={() => {
                setShowFilter(true);
              }}
              >
              {tl("Филтер")}
            </Button>
            </>
        )}
        {!detailedReport && (
            <Button
              variant="contained"
              onClick={openForm}
              >
              {tl("Желим да наставим")}
            </Button>
          )}


        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <PrintButton />
          <GotoHome />
        </Stack>
      </Stack>
    </Box>
  );
};

export default ResultsPage;
