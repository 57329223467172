import { useEffect, useState, Fragment } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { firebaseAuth } from "../providers/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { useTransliteration } from "../providers/transliterate";
import Graphs from "../components/graphs/graphs";
import { useRecoilValue } from "recoil";
import { availableConfigs, currConfigObject } from "../providers/app-state";
import TestSelectorMenu from "../components/test-selection-menu";

import Tooltip from "@mui/material/Tooltip";
import {
  Box,
  Typography,
} from "@mui/material";

// import { fetchBlob } from "../providers/fetch";
// import { GET_XLSX_FILE } from "../constants";
// import { saveAs } from "file-saver";

import { SnackbarKey, useSnackbar } from "notistack";
import { ActionPanel } from "../components/action-panel";
import { LoadingButton } from "@mui/lab";
import Container from "@mui/system/Container";
import { xlsxExport } from "../providers/xlsx-export";

export default function AdminPage() {
  const navigate = useNavigate();
  const tl = useTransliteration();

  const [user, loading] = useAuthState(firebaseAuth);

  const [downloading, setDownloading] = useState(false);

  const cfg = useRecoilValue(currConfigObject);
  const currentCount =
    useRecoilValue(availableConfigs).find((c) => c.id === cfg.id)?.count || 0;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/login", { replace: true });
  }, [user, loading, cfg.id, navigate]);

  // const downloadFile = async () => {
  //   setDownloading(true);
  //   closeSnackbar();

  //   let xlsSnack: SnackbarKey = "";

  //   if (currentCount > 750) {
  //     xlsSnack = enqueueSnackbar(
  //       tl("Припремамо Excel фајл. Преузимање ће почети аутоматски."),
  //       {
  //         variant: "info",
  //         autoHideDuration: 4000,
  //         style: { maxWidth: 300 },
  //         anchorOrigin: { vertical: "top", horizontal: "right" },
  //       }
  //     );
  //   }

  //   const blob = await fetchBlob(GET_XLSX_FILE + cfg.id);
  //   closeSnackbar(xlsSnack);
  //   setDownloading(false);
  //   saveAs(blob, cfg.id);
  // };

    const downloadFile = async () => {
    setDownloading(true);
    closeSnackbar();

    let xlsSnack: SnackbarKey = "";

    if (currentCount > 100) {
      xlsSnack = enqueueSnackbar(
        tl("Припремамо Excel фајл. Преузимање ће почети аутоматски."),
        {
          variant: "info",
          autoHideDuration: 4000,
          style: { maxWidth: 300 },
          anchorOrigin: { vertical: "top", horizontal: "right" },
        }
      );
    }

    await xlsxExport(cfg.id);

    closeSnackbar(xlsSnack);
    setDownloading(false);

  };

  const Actions = () => {
    return (<Box
    sx={{ m: 2 }}
    style={{
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      alignContent: "stretch",
    }}
  >
    <Typography variant="h6">{tl(cfg.iatName)}</Typography>
    <TestSelectorMenu title={tl("Изабери")} />
      <Tooltip title={tl("Преузми Excel фајл")}>
        <span>
        <LoadingButton
        startIcon={<FileDownloadIcon />}
          onClick={downloadFile}
          color="primary"
          //variant="contained"
          loading={downloading}
          disabled={false}
        >
          {tl("XLSX")}
        </LoadingButton>
        </span>
      </Tooltip>
    {/* </Toolbar> */}
  </Box>);
  }


  if (!user) return null;

  return (
    <>
      <ActionPanel>
        <Actions/>
      </ActionPanel>
      <div style={{height: 16}}></div>
      <Container maxWidth="xl">
        <Graphs />
      </Container>
    </>
  );
}
