
const isWebp = (b: Uint8Array) => {
    //  0  1  2  3 | 4  5  6  7 | 8  9 10 11 12 13 14
    // 52 49 46 46 |xx xx xx xx |57 45 42 50 56 50 38
    if (!b || b.length < 14) return false;
    return b[0] === 0x52 && b[1] === 0x49 && b[2] === 0x46 && b[3] === 0x46 && b[8] === 0x57 && b[9] === 0x45 && b[10] === 0x42 && b[11] === 0x50 && b[12] === 0x56 && b[13] === 0x50 && b[14] === 0x38;
}

// image/webp

const isPng = (b: Uint8Array) => {
    if (!b || b.length < 4) return false;
    return b[0] === 0x89 && b[1] === 0x50 && b[2] === 0x4e && b[3] === 0x47;
}

const isGif = (b: Uint8Array) => {
    if (!b || b.length < 4) return false;
    return b[0] === 0x47 && b[1] === 0x49 && b[2] === 0x46 && b[3] === 0x38;
}

const isJpg = (b: Uint8Array) => {
    if (!b || b.length < 4) return false;
    return b[0] === 0xff && b[1] === 0xd8 && b[2] === 0xff && (b[3] === 0xdb || b[3] === 0xe0 || b[3] === 0xe1);
}


export function getMimeTypeOfFile(bytes: Uint8Array) {
        
if (isJpg(bytes)) return 'image/jpeg';
if (isPng(bytes)) return 'image/png';
if (isGif(bytes)) return 'image/gif';
if (isWebp(bytes)) return 'image/webp';
console.warn(`Unknown image type`, bytes.slice(0,4));
return 'application/octet-stream'

    // const getMimeType = (signature:string) => {
    //     switch (signature) {
    //         case '89504E47':
    //             return 'image/png'
    //         case '47494638':
    //             return 'image/gif'
    //         case 'FFD8FFDB':
    //         case 'FFD8FFE0':
    //         case 'FFD8FFE1':
    //             return 'image/jpeg'
    //         // case '504B0304':
    //         //     return 'application/zip'
    //         // case '25504446':
    //         //     return 'application/pdf'
    //         default:
    //             console.warn(`Unknown image type (magic=${signature})`)
    //             return 'application/octet-stream'
    //     }
    // }
    
    // const magic = bytes.slice(0,4);
    // let hex = "";
    // magic.forEach((byte) => {
    //     hex+=byte.toString(16)
    // })
    // return getMimeType(hex.toUpperCase());
}