import { useRef, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { Box, Button, Fab, Stack, Tooltip, Typography } from "@mui/material";
import AppTitle from "../layout/appbar";
import { useTransliteration } from "../providers/transliterate";
import { useRecoilValue } from "recoil";
import { currConfigObject } from "../providers/app-state";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../providers/firebase";
//import { appIsDarkMode } from "../theme";
import { IATCategories, IATCategory } from "../iat-types";
import CardItem from "../components/question/card-item";
import { FAB_MARGIN } from "../constants";
import PrintIcon from '@mui/icons-material/Print';
import { copyDirectLink } from "../utils/copy-link";

export default function TestKey() {
//  const id = useParams().id;

  const navigate = useNavigate();
  const tl = useTransliteration();
  const cfg = useRecoilValue(currConfigObject);
  
  const [user, loading] = useAuthState(firebaseAuth);

  const categoryArray = useRef<IATCategory[]>([]);
  
  useEffect(() => {
      if (loading) return;
      if (!user) navigate("/login", { replace: true });
      
      categoryArray.current = [];
      for(let c in cfg.categories){
        categoryArray.current.push(cfg.categories[c as IATCategories])
      }
      //categoryArray.current = categoryArray.current.reverse();

    }, [user, loading, cfg, navigate]);

    
    const Category = ({cat}:{cat:IATCategory}) => {

        return (
            <>
            <Typography variant="h5" style={{breakAfter: "avoid"}}>{`${tl(cat.label.n).toLocaleUpperCase()} (${cat.datalabel})`}</Typography>
            <Stack direction="row" flexWrap={"wrap"} >
            {cat.items.map((items,index)=>{
              return <Stack sx={{maxWidth: "md", alignItems: "center", justifyItems: "space-between"}} spacing={2} key={index}>
                   <Box sx={{mr: 1, ml:1, mt: 1}}>
                      <CardItem payload={items} size={150} />
                   </Box>
                    <Typography variant="caption">{tl(`Индекс: ${index}`)}</Typography>
                </Stack> 
            })
          }
          </Stack>
            </>
        )
    }

  return (
    <div>
      <AppTitle title={tl(cfg.iatName + " – индекс/кључ")} />
      <Stack sx={{ maxWidth: "l", m: "auto", p: 2, alignItems: "center" }} spacing={2} className="printableContainer">
        <Typography variant="h4">{tl(cfg.iatName)}</Typography>
          <Typography variant="subtitle1">
            {tl(cfg.description)}
          <Tooltip title={tl("Копирај директни линк")}>
            <Button style={{textTransform: "none"}} onClick={async ()=> await copyDirectLink(cfg.id)}>
              { `(ID: ${cfg.id})`}
            </Button>
        </Tooltip>
          </Typography>
        <Typography variant="subtitle2">{tl(`Број питања по блоковима: ${cfg.questionsPerBlock.join(", ")}`)}</Typography>
        {categoryArray.current.map( (cat, idx) => {
          return <Category cat={cat} key={cat.datalabel + idx}/>
        })}
      </Stack>
      <Tooltip title={tl("Одштампај или сними…")}>
          <Fab
            style={{
              position: "fixed",
              bottom: FAB_MARGIN,
              right: FAB_MARGIN,
            }}
            color="secondary"
            onClick={() => window.print()}
          >
            <PrintIcon />
          </Fab>
        </Tooltip>
    </div>
  );
}
